import { types } from "./types";

const {
  docs: {
    REMOVE_DOCUMENT_FROM_STORE,
    SET_CURRENT_DOCUMENT,
    SET_LIST_OF_DOCUMENTS,
    SET_ETB_DOCUMENT,
    SET_FRESHEST_ETB_DOCUMENT,
  },
} = types;

const initialState = {
  documents: null,
  currentDocument: null,
  ETBDocument: null,
  FreshestETBDocument: null,
};

const documentsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LIST_OF_DOCUMENTS:
      return {
        ...state,
        documents: Object.values(payload),
      };
    case REMOVE_DOCUMENT_FROM_STORE:
      return {
        ...state,
        documents: state.documents.filter((i) => i.id !== payload),
      };
    case SET_CURRENT_DOCUMENT:
      return {
        ...state,
        currentDocument: payload,
      };
    case SET_ETB_DOCUMENT:
      return {
        ...state,
        ETBDocument: payload,
      };
    case SET_FRESHEST_ETB_DOCUMENT:
      return {
        ...state,
        FreshestETBDocument: payload,
      };
    default:
      return state;
  }
};

export default documentsReducer;
