import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import AppRoutes from "./routing/routes";
import Header from './components/header';
import { ModalProvider } from "./components/ui-kit/modal-context/ModalContext";
import store from './store';

import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.scss";

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <ModalProvider>
          <div className="App">
            <Header />
            <AppRoutes />
          </div>
        </ModalProvider>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
