import { useState, useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";

// import { requestsThunks } from "actions-thunks/requests-thunks";
// import { accountThunks } from "actions-thunks/account-thunks";
import { ModalContext } from "../ui-kit/modal-context/ModalContext";
import CreateNewRequestModal from "./modals/CreateNewRequestModal";
import CreateRiskSettingsModal from "./modals/CreateRiskSettingsModal";
import CreateCustomRequestModal from "./modals/CreateCustomRequestModal";
import CreateToggleAccountModal from "./modals/CreateToggleAccountModal";
import { constants } from "utilities/constants";
import CreateNewMarketDataRequest from "./modals/CreateNewMarketDataRequest";
import { tradeGroupThunks } from "action-thunks/tradeGroup-thunks";
import { notificationHelpers } from "components/notification/helpers";
import { defaultCategories } from "./modals/helpers";

const { categoryDataType, RequestTypes } = constants;

const NewRequest = ({
  isActiveRequestsTable,
  multipleTraders,
  setCheckedItems,
  setIsModalOpen,
  additionalClassName,
  disabled,
  disableTraderDropdown,
  filteredCategoriesArray,
}) => {
  const { TGAccountsList } = useSelector(
    (state) => state.tradeGroup
  );
  const [activeCategory, setActiveCategory] = useState(null);
  const [activeTraderIds, setActiveTraderIds] = useState(null);

  const dispatch = useDispatch();

  const { showModal, hideModal } = useContext(ModalContext);

  useEffect(() => {
    if (multipleTraders?.length) {
      const newActiveTraderIds = [];
      multipleTraders.forEach((t) => {
        newActiveTraderIds.push(t.values.traderId);
      });
      setActiveTraderIds(newActiveTraderIds);
    } else {
      setActiveTraderIds(null);
    }
  }, [multipleTraders]);

  const categories = useMemo(() => {
    if (filteredCategoriesArray) {
      const defaultCats = defaultCategories();

      return defaultCats.filter(
        (category) => !filteredCategoriesArray.includes(category.value)
      );
    }
    return defaultCategories();
  }, [filteredCategoriesArray]);

  const traderIdsList = useMemo(() => {
    if (TGAccountsList) {
      return TGAccountsList.map((a) => ({
        value: a.reference.client,
        label: `${a.reference.client} (${a.personal.firstName} ${a.personal.lastName})`,
        data: a
      }));
    }
  }, [TGAccountsList]);

  const handleUpdateCategory = (selectedCategory) => {
    const newCategory = categories.find(
      (category) => category.value === selectedCategory
    );
    setActiveCategory(newCategory);
  };
  const handleUpdateTraderIds = (selectedTraderIds, setOneID = false) => {
    if (activeTraderIds === null || setOneID) {
      setActiveTraderIds(selectedTraderIds);
    } else {
      const newArr = [...activeTraderIds];
      selectedTraderIds.forEach((i) => {
        if (!newArr.includes(i)) {
          newArr.push(i);
        }
      });
      setActiveTraderIds(newArr);
    }
  };

  const handleCloseModal = () => {
    setCheckedItems && setCheckedItems([]);
    setActiveTraderIds(null);
    setActiveCategory(null);
    hideModal();
    setIsModalOpen && setIsModalOpen(false);
  };

  // const sendStatisticRequest = ({ body, type }) => {
  //   let requestBody = "";
  //   if (type === "RISKSETTING") {
  //     requestBody = `Create request: Risk Settings: ${body.label}`;
  //   } else if (type === "INQUIRY") {
  //     requestBody = `Create request: Custom`;
  //   } else {
  //     requestBody = `Create request: Account Maintenance: Enable ${body.ext.action} Account`;
  //   }
  // };

  const handleSubmitModal = async ({
    body,
    traderRef,
  }) => {
    const { label, ...rest } = body;
    let requestBody = rest;

    // sendStatisticRequest({ body, type, isCustomForm })

    const foundTrader = TGAccountsList.find(
      (a) => a.reference.client === traderRef
    );
    if (requestBody.type === RequestTypes.TRADING_ACCESS) {
      // check if trader with same status that request - call error
      const status =
        requestBody?.ext?.action === "DISABLE"
          ? "AccountSuspended"
          : "AccountEnabled";
      if (foundTrader.status === status) {
        notificationHelpers.errorRequest(
          foundTrader?.reference?.client,
          dispatch
        );
        handleCloseModal();
        return;
      }
    }

    if (foundTrader) {
      dispatch(
        tradeGroupThunks.createDataRequestTC({
          accID: foundTrader._id,
          body: requestBody,
          traderID: foundTrader.reference.client,
        })
      );
    }
    
    dispatch(tradeGroupThunks.getRiskSettingsMetadataTC());
    handleCloseModal();
  };

  const handleNewRequestClick = () => {
    setIsModalOpen && setIsModalOpen(true);
    showModal(
      <CreateNewRequestModal
        disableTraderDropdown={disableTraderDropdown}
        categories={categories}
        traderIdsList={traderIdsList}
        activeTraderIds={activeTraderIds}
        multipleTraders={multipleTraders}
        setActiveTraderIds={setActiveTraderIds}
        updateCategory={handleUpdateCategory}
        updateTraderIds={handleUpdateTraderIds}
        closeModal={handleCloseModal}
      />
    );
  };

  const commonModalsProps = {
    submitModal: handleSubmitModal,
    closeModal: handleCloseModal,
    updateCategory: handleUpdateCategory,
    updateTraderIds: handleUpdateTraderIds,
    TGAccountsList,
    multipleTraders,
    activeCategory,
    activeTraderIds,
    categories,
    traderIdsList,
  };

  useEffect(() => {
    if (activeCategory) {
      switch (activeCategory.value) {
        case categoryDataType.RISKSETTING:
          showModal(<CreateRiskSettingsModal {...commonModalsProps} />);
          break;
        case "disableAccount":
        case "enableAccount":
          showModal(
            <CreateToggleAccountModal
              {...commonModalsProps}
              accountRequestType={
                activeCategory.value === "enableAccount" ? "enable" : "disable"
              }
            />
          );
          break;
        case categoryDataType.CUSTOM:
          showModal(<CreateCustomRequestModal {...commonModalsProps} />);
          break;
        case categoryDataType.MARKETDATA:
          showModal(<CreateNewMarketDataRequest {...commonModalsProps} />);
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategory]);

  return (
    <div className={cn(`account-overview-table-header`, additionalClassName)}>
      {isActiveRequestsTable && (
        <h6 className="account-title">Active Requests</h6>
      )}
      <button disabled={disabled} onClick={handleNewRequestClick} className="main-button">
        <>
          <img src={`${process.env.PUBLIC_URL}/icons/plus.svg`} alt="plus" />{" "}
          New Request
        </>
      </button>
    </div>
  );
};

export { NewRequest };
