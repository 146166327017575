import { isValidElement, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";

import TableComponent from "components/ui-kit/react-table/TableComponent";
import { supportTableUtils } from "components/ui-kit/react-table/supportTableUtils";
import SupportStateController from "components/ui-kit/state-controller/SupportStateController";
import TradersHelpers from "./helpers";
import { NewRequest } from "components/requests/NewRequest";
import { constants } from "utilities/constants";
import { tradeGroupThunks } from "action-thunks/tradeGroup-thunks";

import styles from "./styles.module.scss";

const { categoryDataType } = constants;
const { tableFormattedData, setCellClassName, findActiveTP } = TradersHelpers;

const MarketData = ({ adminGroupData }) => {
  const { TGAccountsList } = useSelector((state) => state.tradeGroup);
  const { tradingPlatforms, marketBundles, marketdataProviders } = useSelector(
    (state) => state.marketdata
  );

  const dispatch = useDispatch();

  const [checkedItems, setCheckedItems] = useState([]);
  const [visibleItems, setVisibleItems] = useState(0);
  const [activeTP, setActiveTP] = useState(null);
  const [sortedMDAccounts, setSortedMDAccounts] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadingList, setIsLoadingisLoadingList] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const activeList = useMemo(() => {
    if (sortedMDAccounts && activeTP) {
      const joinedActiveTPName = `${activeTP?.software} ${activeTP?.softwareType}`;
      return sortedMDAccounts[joinedActiveTPName];
    } else {
      return [];
    }
  }, [sortedMDAccounts, activeTP]);

  useEffect(() => {
    const fetchRequestsPeriodically = () => {
      if (!isModalOpen) {
        if (isInitialLoad) setIsLoadingisLoadingList(true);
        dispatch(
          tradeGroupThunks.getTGAccountsListTC([
            "marketData",
            "openMDrequest",
            "accountsOnly",
            "firstEnableRequest",
            "riskSettings",
          ])
        ).finally(() => {
          setIsInitialLoad(false);
          setIsLoadingisLoadingList(false);
        });
      }
    };

    fetchRequestsPeriodically();

    const intervalId = setInterval(fetchRequestsPeriodically, 3000);

    return () => clearInterval(intervalId);
  }, [dispatch, isModalOpen, isInitialLoad]);

  // useEffect(() => {
  //   if (activeList !== null && activeList.length > 0 && visibleItems === 0) {
  //     const defaultVisibleItems = activeList.length > 10 ? 10 : activeList.length;
  //     setVisibleItems(defaultVisibleItems);
  //   }
  // }, [activeList]);

  useEffect(() => {
    if (TGAccountsList !== null) {
      let sortedAccounts = null;
      if (TGAccountsList.length) {
        sortedAccounts = {};
        TGAccountsList.forEach((item) => {
          const { software, softwareType } = item;
          if (software) {
            const combinedValue = `${software} ${softwareType}`;
  
            if (!sortedAccounts[combinedValue]) {
              sortedAccounts[combinedValue] = [];
            }
  
            sortedAccounts[combinedValue].push(item);
          }
        });
      }

      setSortedMDAccounts(sortedAccounts);
    }
  }, [TGAccountsList]);

  useEffect(() => {
    if (
      sortedMDAccounts &&
      Object.keys(sortedMDAccounts).length > 0 &&
      tradingPlatforms &&
      !activeTP
    ) {
      const newActiveTP = findActiveTP(
        tradingPlatforms,
        Object.keys(sortedMDAccounts)[0]
      );
      setActiveTP(newActiveTP);
    }
  }, [sortedMDAccounts, tradingPlatforms, activeTP]);

  useEffect(() => {
    if (activeTP && sortedMDAccounts) {
      setVisibleItems((prevVisibleItems) => {
        // Only set if previous is zero (initial load) or if activeList has fewer items
        const defaultVisibleItems = activeList.length > 10 ? 10 : activeList.length;
        if (prevVisibleItems === 0 || prevVisibleItems > defaultVisibleItems) {
          return defaultVisibleItems;
        }
        return prevVisibleItems;
      });
    }
  }, [activeTP]);
  

  const checkboxColumnsHandler = (row) => {
    if (isValidElement(row.original.RequestedMarketData)) {
    } else {
      setCheckedItems((prev) => {
        const currentIndex = prev.find(
          (p) => p.original?.traderId === row.original?.traderId
        );
        if (currentIndex === undefined) {
          // Not currently checked, add it
          return [...prev, row];
        } else {
          // Currently checked, remove it
          return prev.filter(
            (r) => r.original?.traderId !== row.original?.traderId
          );
        }
      });
    }
  };

  const starColumsHandler = (rowID, status) => {};

  const onTPButtonClick = (tpName) => {
    const fountTP = findActiveTP(tradingPlatforms, tpName);
    if (fountTP) {
      setActiveTP(fountTP);
      setCheckedItems([])
    }
  };

  const tradingPlatformRef = activeTP?.reference;

  return (
    <div className="page-wrapper">
      <div className={styles.marketdata}>
        <div className={styles.header}>
          <div className="d-flex f-gap-20 align-items-center">
            <h3>Market Data</h3>
            {!isLoadingList && <div className={styles.tpBlock}>
              {sortedMDAccounts &&
                Object.keys(sortedMDAccounts)?.map((i) => (
                  <span
                    onClick={() => onTPButtonClick(i)}
                    className={cn(styles.tpItem, {
                      [styles["active-trading-platform"]]:
                        i === `${activeTP?.software} ${activeTP?.softwareType}`,
                    })}
                    key={crypto.randomUUID()}
                  >
                    {i}
                  </span>
                ))}
            </div>}
          </div>
          <NewRequest
            setIsModalOpen={setIsModalOpen}
            disableTraderDropdown
            disabled={checkedItems?.length < 1}
            additionalClassName={[styles.newRequest]}
            adminGroupData={adminGroupData}
            renderFullListAfterRequest={false}
            multipleTraders={checkedItems}
            setCheckedItems={setCheckedItems}
            filteredCategoriesArray={[
              categoryDataType.RISKSETTING,
              "disableAccount",
              "enableAccount",
            ]}
          />
        </div>
        {!isLoadingList && activeList && (
          <div className={styles.body}>
            <div className={styles["table-body"]}>
              <TableComponent
                data={tableFormattedData(
                  true,
                  activeList.slice(-visibleItems),
                  marketBundles,
                  marketdataProviders,
                  tradingPlatformRef
                )}
                // isLoading={isLoading}
                checkedItems={checkedItems}
                dateColumns={["created", "updated"]}
                checkboxColumsHandler={checkboxColumnsHandler}
                starColumsHandler={starColumsHandler}
                formattingForDateColumns={supportTableUtils.tableTimeFormat}
                showSearch={false}
                setCellClassName={setCellClassName}
                itemsToUseSorting={[
                  "traderid",
                  "accountstate",
                  "platform",
                  "fullname",
                ]}
              />
            </div>
            <SupportStateController
              setVisibleItems={setVisibleItems}
              visibleItems={visibleItems}
              items={activeList}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MarketData;
