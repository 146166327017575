import { types } from "./types";

const {
  marketdata: {
    SET_MARKETDATA_PROVIDERS,
    SET_MARKET_BUNDLES,
    SET_TRADING_PLATFORMS,
  },
} = types;

const initialState = {
  marketdataProviders: null,
  marketBundles: null,
  tradingPlatforms: null,
  requestedMarketsData: null,
};

const marketdataReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_MARKETDATA_PROVIDERS:
      return {
        ...state,
        marketdataProviders: payload,
      };
    case SET_MARKET_BUNDLES:
      return {
        ...state,
        marketBundles: payload,
      };
    case SET_TRADING_PLATFORMS:
      return {
        ...state,
        tradingPlatforms: payload,
      };
    default:
      return state;
  }
};

export default marketdataReducer;
