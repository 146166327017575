import { Modal } from "components/ui-kit/Modal/Modal";
import cn from "classnames";
import CopyToClipboard from "components/ui-kit/copy-to-clipboard/CopyToClipboard";

import styles from "./styles.module.scss";
import "./global.scss";
import { useMemo } from "react";

const OnboardingCredsModal = ({ closeModal, item }) => {
  const { personal, software, reference, password } = item.item;

  const setFullName = useMemo(() => {
    if (personal) {
      return `${personal.firstName} ${personal.lastName}`;
    }
  }, [personal]);

  return (
    <div className="modal-window">
      <Modal
        className="cabinet-modals"
        overlayClassName="request-overlay"
        styleType="zimtra-primary"
        isOpen={true}
        hideBlur
        hasCloseIcon={false}
        onClickOutside={closeModal}
      >
        <Modal.Header
          className="modal-inner-header"
          onClose={closeModal}
          title={<h2 className="zimtra-h2">Trading Platform Credentials</h2>}
        />
        <Modal.Body
          className={cn("modal-inner-body", styles["onboarding-modal-body"])}
        >
          <div className="d-flex flex-col gap-10">
            <div className="d-flex gap-10">
              <strong>Full name:</strong>
              {setFullName}
            </div>
            <div className="d-flex gap-10">
              <strong>Trading Platform:</strong>{software}
            </div>
          </div>
          <hr />
          <div className="d-flex flex-col gap-10">
            <div className="d-flex gap-10">
              <strong>TraderID:</strong>
              <CopyToClipboard text={reference.client} />
            </div>
            <div className="d-flex gap-10">
              <strong>Password:</strong>
              <CopyToClipboard text={password || ""} />
            </div>
          </div>
          <hr />
        </Modal.Body>
        <Modal.Footer
          className={cn(
            "modal-inner-footer",
            styles["onboarding-modal-footer"]
          )}
        >
          <button className="main-button" onClick={closeModal}>
            Ok
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default OnboardingCredsModal;
