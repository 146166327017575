import { documentAPI } from "../api/document-api";
import { errorTypes } from "../reducers/types";
import {
  setErrorAC,
  setLoadingAC,
  setListOfDocumentsAC,
  setCurrentDocumentAC,
  removeDocumentFromStoreAC,
  setETBDocumentAC,
} from "./actions";
import { authThunks } from "./auth-thunks";

const {
  docs: {
    CURRENT_DOCUMENT_ERROR,
    DELETE_DOCUMENT_ERROR,
    DOCUMENT_LIST_ERROR,
    GET_DOC_ATTACHMENT_ERROR,
    GET_AND_DOWNLOAD_FILE_ERROR,
  },
} = errorTypes;

export const documentThunks = {
  getListDocumentsTC: () => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await documentAPI.fetchDocumentListFromS3();
      if (response.status === 200) {
        const data = await response.json();
        dispatch(setListOfDocumentsAC(data.documents));
      }
      dispatch(setErrorAC({ type: DOCUMENT_LIST_ERROR, message: "" }));
    } catch (error) {
      console.trace(error);
      if (error.response?.status === 401) dispatch(authThunks.logoutTC());
      dispatch(
        setErrorAC({
          type: DOCUMENT_LIST_ERROR,
          message: error.response?.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  getCurrentDocumentTC: (doc) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await documentAPI.fetchDocumentFromS3(doc.id);
        // ? dispatch(setETBDocumentAC({ ...doc, format: "MARKUP", body: response }))
        dispatch(setCurrentDocumentAC({ ...doc, format: "MARKUP", body: response }));
      dispatch(setErrorAC({ type: CURRENT_DOCUMENT_ERROR, message: "" }));
    } catch (error) {
      dispatch(
        setErrorAC({
          type: CURRENT_DOCUMENT_ERROR,
          message: error.response?.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  deleteDocumentTC:
    (id, parentID = "") =>
    async (dispatch) => {
      try {
        dispatch(setLoadingAC(true));
        const response = await documentAPI.deleteDocument(id);
        if (response.status === 200) {
          dispatch(removeDocumentFromStoreAC(id));
          dispatch(documentThunks.getCurrentDocumentTC(parentID));
        }
        dispatch(setErrorAC({ type: DELETE_DOCUMENT_ERROR, message: "" }));
      } catch (error) {
        dispatch(
          setErrorAC({
            type: DELETE_DOCUMENT_ERROR,
            message: error.response.data?.message,
          })
        );
      } finally {
        dispatch(setLoadingAC(false));
      }
    },
  getAndDownloadAttachmentTC: (currentDocument, name) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await documentAPI.getAttachment(currentDocument, name);
      if (response.status === 200) {
        const nameFromResponseHeaders = atob(response.headers["x-filename"]);
        const mimetype = response.headers["content-type"].split(";")[0];
        const data = new Blob([response.data], { type: mimetype });
        const csvURL = window.URL.createObjectURL(data);
        let tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", nameFromResponseHeaders);
        tempLink.click();
      }
      dispatch(setErrorAC({ type: GET_DOC_ATTACHMENT_ERROR, message: "" }));
    } catch (error) {
      dispatch(
        setErrorAC({
          type: GET_DOC_ATTACHMENT_ERROR,
          message: error.response.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  getAndDownloadFileTC: (fileId) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await documentAPI.getFile(fileId);
      if (response.status === 200) {
        const data = new Blob([response.data]);
        const csvURL = window.URL.createObjectURL(data);
        let tempLink = document.createElement("a");
        const nameFromResponseHeaders = atob(response.headers["x-filename"]);
        tempLink.href = csvURL;
        tempLink.setAttribute("download", nameFromResponseHeaders);
        tempLink.click();
      }
      dispatch(setErrorAC({ type: GET_AND_DOWNLOAD_FILE_ERROR, message: "" }));
    } catch (error) {
      dispatch(
        setErrorAC({
          type: GET_AND_DOWNLOAD_FILE_ERROR,
          message: error.response.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
};
