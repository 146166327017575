import { profileAPI } from "../api/profile-api";
import {
  setProfileAC,
  setLoadingAC,
} from "./actions";
import { authThunks } from "./auth-thunks";

//profile thunks
export const profileThunks = {
  getProfileTC: () => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await profileAPI.getProfile();
      if (response.status === 200) {
          dispatch(setProfileAC(response.data));
      }
    } catch (error) {
      if (error.response?.status === 401) {
        dispatch(authThunks.logoutTC());
      }
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  // updateProfileTC: (profileData) => async (dispatch) => {
  //   try {
  //     dispatch(setLoadingAC(true));
  //     const newProfileData = {...profileData};
  //     delete newProfileData.avatar
  //     const response = await profileAPI.updateProfile(newProfileData);
  //     if (response.status === 200) {
  //       dispatch(updateProfileAC(profileData));
  //     }
  //     dispatch(setErrorAC({ type: UPDATE_PROFILE_ERROR, message: "" }));
  //   } catch (error) {
  //     dispatch(
  //       setErrorAC({
  //         type: UPDATE_PROFILE_ERROR,
  //         message: error.response.data?.message,
  //       })
  //     );
  //   } finally {
  //     dispatch(setLoadingAC(false));
  //   }
  // }
}