import { instance, setHeader } from "./auth-api";

//INFO: TGM - trade group manager

export const tradegroupAPI = {
  getTGsList: async () => {
    return await instance.get("trade-group/list", {
      headers: setHeader(),
    });
  },
  getAccountsList: async (withParams) => {
    let params = {};
    if (withParams) {
      withParams.forEach((p) => (params[p] = 1));
    }
    return await instance.get(`application/extended-list`, {
      headers: setHeader(),
      params,
    });
  },
  createTradingPackage: async (body) => {
    const response = await instance.post("application", body, {
      headers: setHeader(),
    });
    return response;
  },
  setApplicationStatus: async (body) => {
    const response = await instance.put("application/status", body, {
      headers: setHeader(),
    });
    return response;
  },
  getTGBalance: async (TGMid) => {
    return await instance.get(`trade-group/${TGMid}/balance`, {
      headers: setHeader(),
    });
  },
  getAccountsRequests: async () => {
    return await instance.get("request/list?statusCode=unresolved", {
      headers: setHeader(),
    });
  },
  getRSMetadata: async () => {
    const response = await instance.get(
      "request/risk-setting-metadata?needDefault=1",
      {
        headers: setHeader(),
      }
    );
    return response;
  },
  createDataRequest: async (accID, body) => {
    body = { ...body, applicationID: accID };
    const response = await instance.post("request", body, {
      headers: setHeader(),
    });
    return response;
  },
  updateRequest: async (requestID, body = {}) => {
    const response = await instance.put(
      `request/${requestID}`,
      body,
      { headers: setHeader() }
    );
    return response;
  },
  setRequestStatusAction: async (requestID, body = {}, action = "") => {
    const response = await instance.put(
      `request/${requestID}/${action}`,
      body,
      { headers: setHeader() }
    );
    return response;
  },
  getAgencyList: async () => {
    return await instance.get("agency/list", {
      headers: setHeader(),
    });
  },
};
