import { useEffect } from "react";
import cn from "classnames";
import { Overlay } from "../Overlay/Overlay";
import styles from "./ModalBase.module.scss";

const freezeScroll = () => {
  document.body.style.overflow = "hidden";
};
const unfreezeScroll = () => {
  document.body.style.overflow = "auto";
};

export const ModalBase = ({
  isOpen,
  children,
  onClickOutside,
  containerClassName,
  overlayClassName,
  className = "base",
  hideBlur,
  styleType = "primary",
  hasCloseIcon = true,
}) => {
  useEffect(() => {
    if (isOpen) {
      freezeScroll();
    }
    return () => {
      unfreezeScroll();
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div
      className={cn([styles[styleType]], containerClassName)}
      data-testid="modal-base"
    >
      <Overlay
        className={cn(styles.overlay, overlayClassName)}
        isOpen={isOpen}
        hideBlur={hideBlur}
        onClick={onClickOutside}
      />
      <div
        className={cn(styles["base-modal"], className, {
          [styles.visible]: isOpen,
        })}
        data-testid="modal-base__content"
      >
        {hasCloseIcon && (
          <span>X</span>
        )}
        {children}
      </div>
    </div>
  );
};

ModalBase.displayName = "ModalBase";
