import { forwardRef } from "react";
import moment from "moment";

import { getTotalPriceHandler } from "../../../helpers";
import ModalActions from "./modalActions";
import DateInput from "components/ui-kit/date-input/DateInput";

const ModalConfirmation = forwardRef(
  ({ buttonItem, primaryEntitlements, onDateChange, ...props }) => {
    return (
      <div className="confirmation-block">
        <div className="left-block">
          <div>
            <p className="bold-titles total-monthly-cost">
              Total monthly cost:
            </p>
            <ul>
              <li>
                <div className="monthly-cost-item">
                  <span>current:</span>{" "}
                  <span>
                    {getTotalPriceHandler(
                      primaryEntitlements,
                      props.filterMarketsToTradingPlatform
                    )}
                  </span>
                </div>
              </li>
              <li>
                <div className="monthly-cost-item">
                  <span>after the change:</span>{" "}
                  <span>
                    {getTotalPriceHandler(
                      props.localState?.markets,
                      props.filterMarketsToTradingPlatform
                    )}
                  </span>
                </div>
              </li>
            </ul>
          </div>
          <div className="effective-date-block">
            <div className="effective-date-calendar">
              <span className="bold-titles">Effective date: </span>
              <div className="d-flex align-items-center calendar-container modal-row">
              <DateInput
                  name="start"
                  type="date"
                  min={moment.utc().startOf("day").format("YYYY-MM-DD")}
                  max={moment
                    .utc()
                    .add(1, "year")
                    .startOf("day")
                    .format("YYYY-MM-DD")}
                  value={moment
                    .utc(props.localState.effectiveDate)
                    .format("YYYY-MM-DD")}
                  onChange={(e) => {
                    const utcDate = moment
                      .utc(e.target.value)
                      .format("YYYY-MM-DD");
                    onDateChange(utcDate);
                  }}
                />
              </div>
            </div>
            <div className="monthly-fee-text">
              Please note that the monthly fee is not prorated.
            </div>
          </div>
        </div>
        <ModalActions {...props} />
      </div>
    );
  }
);

export default ModalConfirmation;
