export const constants = {
  paths: {
    TO_GROUP_OVERVIEW: "/group-overview",
    TO_TRADERS: "/traders",
    TO_ONBOARDING: "/onboarding",
    TO_MARKET_DATA: "/market-data",
    TO_PERSONAL_INFO: "/personal-info",
    TO_MONTHLY_STATEMENTS: "/months-statements",
    TO_RESOURCES: "/etb",
    TO_SUPPORT: "/support",
    TO_FAQ: "/faq",
    TO_LOGIN: "/login",
    TO_LOGOUT: "/logout",
  },
  tokens: {
    LOG_IN_TOKEN: "tradergroupToken",
  },
  summaryData: {
    Volume: "Volume",
    OpgBalance: "Opening Balance",
    Deposits: "Deposits",
    AdjNet: "Net P&L (incl. fees)",
    Unrealized: "Unrealized P&L",
    TotalBank: "Current Balance",
  },
  permissionTypes: {
    accounts: {
      ACCOUNT: "account",
      ACCOUNT_ACCOUNTS: "account.accounts",
      ACCOUNT_MANAGE: "account.manage",
      ACCOUNT_USERPROFILE: "account.userprofile",
      ACCOUNT_NOTIFICATION: "account.notifications",
      ACCOUNT_CHANGEPASSWORD: "account.changepassword",
    },
    tradeGroup: {
      TG_ADMIN: "ADMIN",
      TRADEGROUP: "tradeGroup",
      TRADEGROUP_MANAGER: "tradeGroup.manager",
    },
    support: {
      SUPPORT: "support",
      SUPPORT_REQUEST: "support.request",
    },
    document: {
      DOCUMENT: "document",
      DOCUMENT_PUBLIC: "document.public",
      DOCUMENT_EDITOR: "document.editor",
    },
    admin: {
      ADMIN: "admin",
      LOGINS: "admin.login",
      INVITE: "admin.invite",
      FS: {
        MAIN: "admin.fs",
        LIST: "admin.fs.list",
        DOWNLOAD: "admin.fs.download",
        UPLOAD: "admin.fs.upload",
        DELETE: "admin.fs.delete",
      },
    },
  },
  sidebarElementsPermissionType: {
    STATEMENTS: "GET_STATEMENTS"
  },
  categoryDataType: {
    RISKSETTING: "Risk Settings",
    MARKETDATA: "Market Data",
    TRADING_ACCESS: "Account Maintenance",
    CUSTOM: "Custom",
  },
  RequestTypes: {
    TRADING_ACCESS: "TRADING_ACCESS",
    MARKETDATA: "MARKETDATA",
    RISKSETTING: "RISKSETTING",
    INQUIRY: "INQUIRY",
    ALL: "ALL",
  },
  contextMenuOptionType: {
    OPEN_CHAT: "open-chat",
    CANCEL_REQUEST: "cancel-request",
    RESOLVE_REQUEST: "resolve-request",
  },
};

const actionData = {
  ENABLE: "Enable",
  DISABLE: "Disable",
  INCREASE: "Increase",
  DECREASE: "Decrease",
  PENDING: "Pending",
};

const statusData = {
  OPEN: "Open",
  CLOSED: "Closed",
  COMPLETED: "Resolved",
  CANCELED: "Canceled",
  CREATED: "Created",
  BLOCKED: "Blocked",
  ONHOLD: "On hold",
  INPROGRESS: "In progress",
  NEW_MESSAGE: "Pending",
  WAITING_FOR_CLIENT: "Your reply needed",
};

const onboardingModalTypes = {
  CREDS: "CREDS",
  TRADING_PACKAGE: "TRADING_PACKAGE",
  EXCHANGE_AGREEMENTS: "EXCHANGE_AGREEMENTS",
  EFFECTIVE_DATE: "EFFECTIVE_DATE",
}

const RequestFormStatusTypes = {
  OPEN: "OPEN",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
  BLOCKED: "BLOCKED",
  ONHOLD: "ONHOLD",
  INPROGRESS: "INPROGRESS",
  DECLINED: "DECLINED",
  PENDING_EOD: "PENDING_EOD",
  WAITING_FOR_CLIENT: "WAITING_FOR_CLIENT",
  WAITING_FOR_3RD_PARTY: "WAITING_FOR_3RD_PARTY",
  NEW_MESSAGE: "NEW_MESSAGE",
  STALE: "STALE",
  START: "start",
  STOP: "stop",
  RESOLVE: "resolve",
};

const notActiveRequestsStates = [
  RequestFormStatusTypes.STALE,
  RequestFormStatusTypes.CANCELED,
  RequestFormStatusTypes.DECLINED,
  RequestFormStatusTypes.COMPLETED,
];

const accountStatusTypes = {
  INCOMPLETE: "Incomplete",
  APPROVED: "Approved",
  ACCOUNT_CREATED: "AccountCreated",
  ACCOUNT_ENABLED: "AccountEnabled",
  ACCOUNT_SUSPENDED: "AccountSuspended",
}

export const separateConstants = {
  RequestFormStatusTypes,
  onboardingModalTypes,
  notActiveRequestsStates,
  accountStatusTypes,
  actionData,
  statusData,
};
