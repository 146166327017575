// /* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import cn from "classnames";

import MarketDataItem from "./rendering-parts/MarketDataItem";
import Bundles from "./rendering-parts/Bundles";
import MarketConfirmWindow from "./rendering-parts/modal/confirmation/modal";
import {
  getBundleName,
  getMarketWithName,
  getTPRefByName,
  getTotalPriceHandler,
  onMarketStatusChangeHandler,
} from "./helpers";

import "./marketdata.scss";

const MarketDataPageModal = ({
  onboardingClient,
  activeTP,
  defaultTraderIds,
  TGAccountsList,
  closeModal,
  copyLocalState,
}) => {
  const { marketBundles, marketdataProviders, tradingPlatforms } = useSelector(
    (state) => state.marketdata
  );

  const referenceKey = onboardingClient ? "onboardingID" : "client";

  const marketBundlesSorted = marketBundles?.sort((a,b) => a.order - b.order);

  const foundCurrentTrader = useMemo(() => {
    if (defaultTraderIds && Array.isArray(defaultTraderIds) && TGAccountsList) {
      return TGAccountsList?.find(
        (t) => t.reference?.[referenceKey] === defaultTraderIds[0]
      );
    }
    return null;
  }, [defaultTraderIds, referenceKey, TGAccountsList]);

  const [buttonItem, setButtonItem] = useState(null);
  const [primaryBundle, setPrimaryBundle] = useState(null);
  const [primaryEntitlements, setPrimaryEntitlements] = useState([]);
  const [localState, setLocalState] = useState(null);
  const [changingStatus, setChangingStatus] = useState(false);
  const [dataWasChanged, setDataWasChanged] = useState(false);

  useEffect(() => {
    if (defaultTraderIds && TGAccountsList) {
      let defaultState = {};
      if (defaultTraderIds.length === 1) {
        const foundTrader = TGAccountsList?.find(
          (t) => t.reference?.[referenceKey] === defaultTraderIds[0]
        );
        if (foundTrader && foundTrader.marketData?.ext?.markets) {
          const foundMarkets = foundTrader.marketData?.ext?.markets;
          const mappedMarkets = foundMarkets.map((m) => m.market);
          defaultState = { markets: mappedMarkets };
          setPrimaryEntitlements(mappedMarkets);
          setPrimaryBundle(setChosenButton(mappedMarkets));
        }
      }
      setLocalState((prevState) => ({
        ...prevState,
        ...defaultState,
        effectiveDate: moment.utc().format("YYYY-MM-DD"),
      }));
    }
  }, [defaultTraderIds, TGAccountsList]);

  useEffect(() => {
    if (copyLocalState && onboardingClient && !!localState?.markets) {
      copyLocalState(localState);
    }
  }, [copyLocalState, localState, onboardingClient]);

  useEffect(() => {
    if (activeTP) {
      setButtonItem(null);
      setPrimaryBundle(null);
      setPrimaryEntitlements([]);
      setChangingStatus(false);
      setDataWasChanged(false);
      setLocalState((prev) => ({
        ...prev,
        markets: []
      }));
    }
  }, [activeTP]);
  
  let bundleButtons = [];

  if (marketBundlesSorted) {
    bundleButtons = marketBundlesSorted.map((m, inx) => {
      return { name: m.name, value: inx };
    });
    bundleButtons.push({
      name: "Custom",
      value: bundleButtons.length,
      disabled: true,
    });
  }

  const getSoftwareDetails = () => {
    if (onboardingClient) {
      const foundTP = tradingPlatforms.find(
        (tp) => tp.reference === activeTP
      );
      return {
        software: foundTP?.software,
        softwareType: foundTP?.softwareType,
      };
    }
    return {
      software: foundCurrentTrader.software,
      softwareType: foundCurrentTrader.softwareType,
    };
  };

  const setChosenButton = (markets) => {
    const { software, softwareType } = getSoftwareDetails();

    const name = getBundleName({
      markets,
      marketdataProviders,
      currentAccount: foundCurrentTrader,
      tradingPlatforms,
      marketBundlesSorted,
      tradingPlatformRef: getTPRefByName(software, softwareType, tradingPlatforms)
    });
    setButtonItem(name);
    return name;
  };

  const onMarketStatusChange = (name, value, reference, depRef) => {
    onMarketStatusChangeHandler({
      name,
      value,
      reference,
      depRef,
      localState,
      setLocalState,
      // copyLocalState,
      filterMarketsToTradingPlatform,
      primaryEntitlements,
      setDataWasChanged,
      setChosenButton,
    });
  };

  const filterMarketsToTradingPlatform = useMemo(() => {
    if (defaultTraderIds && marketdataProviders && foundCurrentTrader) {
      const { software, softwareType } = getSoftwareDetails();

      return marketdataProviders.filter((m) =>
        m.tradingPlatformRefs.includes(
          getTPRefByName(software, softwareType, tradingPlatforms)
        )
      );
    }
  }, [
    activeTP,
    onboardingClient,
    tradingPlatforms,
    marketdataProviders,
    defaultTraderIds,
    foundCurrentTrader,
  ]);

  const handleRequestMarketBundleChange = ({ name }) => {
    const removeMarkets = [];
    const addMarkets = [];
    const newMarkets = getMarketWithName({
      filterMarketsToTradingPlatform,
      name,
    });
  
    filterMarketsToTradingPlatform.forEach((m) => {
      if (
        primaryEntitlements?.includes(m.name) &&
        !newMarkets?.includes(m.name)
      ) {
        removeMarkets.push(m.name);
      }
      if (
        !primaryEntitlements?.includes(m.name) &&
        newMarkets?.includes(m.name)
      ) {
        addMarkets.push(m.name);
      }
    });
  
    setButtonItem(name);
    setChangingStatus(true);
    setDataWasChanged(true);
  
    setLocalState((prevState) => ({
      ...prevState,
      addMarkets,
      markets: newMarkets,
      removeMarkets,
    }));

    if (copyLocalState) {
      copyLocalState(newMarkets)
    }
  };
  

  const handleDiscardMarketDataRequestModal = () => {
    closeModal();
  };

  const handleMarketDataRequestModalDateChange = ({ dateValue }) => {
    setDataWasChanged(true);
    setLocalState({ ...localState, effectiveDate: dateValue });
  };

  const renderMarketsBlock = useMemo(() => {
    if (marketdataProviders) {
      let groups = [];
      for (let i = 0; i < marketdataProviders.length; i++) {
        const el = marketdataProviders[i];
        if (!groups.includes(el.group)) groups.push(el.group);
      }

      return (
        <div
          className={cn("marketdata-main-block", {
            "onboarding-marketdata-main-block": !!onboardingClient,
          })}
        >
          {groups.map((g, inx) => (
            <MarketDataItem
              title={g}
              key={g}
              primaryEntitlements={primaryEntitlements}
              marketdataProviders={marketdataProviders}
              onMarketStatusChange={onMarketStatusChange}
              entitlements={localState?.markets}
              marketItems={filterMarketsToTradingPlatform}
            />
          ))}
        </div>
      );
    }
    return null;
  }, [
    marketdataProviders,
    onboardingClient,
    primaryEntitlements,
    filterMarketsToTradingPlatform,
    localState?.markets,
  ]);

  const monthlyTradingCostValue = useMemo(() => {
    if (localState?.markets) {
      return getTotalPriceHandler(
        localState?.markets,
        filterMarketsToTradingPlatform
      );
    }
    return "N/A";
  }, [localState, filterMarketsToTradingPlatform]);

  return (
    <div className="marketdata-wrapper">
      {bundleButtons && (
        <Bundles
          onboardingClient={onboardingClient}
          bundle={bundleButtons}
          filterMarketsToTradingPlatform={filterMarketsToTradingPlatform}
          handleRequestMarketBundleChange={handleRequestMarketBundleChange}
          buttonItem={buttonItem}
          changingStatus={changingStatus}
          primaryBundle={primaryBundle}
        />
      )}
      {renderMarketsBlock}
      {onboardingClient ? (
        <div className="onboarding-marketdata-monthly-cost">
          <hr />
          <div>Market data monthly cost: {monthlyTradingCostValue}</div>
          <hr />
        </div>
      ) : (
        <MarketConfirmWindow
          buttonItem={buttonItem}
          filterMarketsToTradingPlatform={filterMarketsToTradingPlatform}
          primaryEntitlements={primaryEntitlements}
          localState={localState}
          defaultTraderIds={defaultTraderIds}
          setDataWasChanged={setDataWasChanged}
          dataWasChanged={dataWasChanged}
          handleMarketDataRequestModalDateChange={
            handleMarketDataRequestModalDateChange
          }
          handleDiscardMarketDataRequestModal={
            handleDiscardMarketDataRequestModal
          }
        />
      )}
    </div>
  );
};

export default MarketDataPageModal;
