import { Modal } from "components/ui-kit/Modal/Modal";
import CopyToClipboard from "components/ui-kit/copy-to-clipboard/CopyToClipboard";

import styles from "./styles.module.scss";
import "./global.scss";

const OnboardingExchangeAgreementModal = ({ item, closeModal }) => {
  const { reference, password } = item.item;

  return (
    <div className="modal-window">
      <Modal
        className="new-request-modal cabinet-modals"
        overlayClassName="request-overlay"
        styleType="zimtra-primary"
        isOpen={true}
        hideBlur
        hasCloseIcon={false}
        onClickOutside={closeModal}
      >
        <Modal.Header
          className="modal-inner-header"
          onClose={closeModal}
          title={
            <h2 className="zimtra-h2">Exchange agreements with Sterling</h2>
          }
        />
        <Modal.Body className={styles["onboarding-modal-body-exchange"]}>
          <div className="d-flex flex-col gap-10 mb-10">
            <div className="d-flex gap-10">
              <strong>SEM website:</strong>
              <a className="link" href="https://sem.sterlingtradingtech.com">
                sem.sterlingtradingtech.com
              </a>
            </div>
            <div className="d-flex flex-col gap-10">
              <div className="d-flex gap-10">
                <strong>TraderID:</strong>
                <CopyToClipboard text={reference.client} />
              </div>
              <div className="d-flex gap-10">
                <strong>Password:</strong>
                <CopyToClipboard text={password || "N/A"} />
              </div>
            </div>
          </div>
          <hr />
          <p>
            Before we can add market data please fill out NYSE agreements with
            Sterling using the credentials above.
          </p>
          <p>
            If you need help please follow{" "}
            <a className="link" href="faq/617a7d4d2dba046041e9a015">
              the instructions.
            </a>
          </p>
          <p>
            <strong>Note:</strong> the NYSE agreement must be approved by the
            exchange prior to market data activation. It can take up to 48
            hours. All other market data requests are enabled immediately.
          </p>
          <hr />
        </Modal.Body>
        <Modal.Footer className="modal-inner-footer d-flex justify-content-between">
          <button className="main-button" onClick={closeModal}>
            Ok
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default OnboardingExchangeAgreementModal;
