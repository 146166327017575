import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import SidebarNavigation from "../sidebar-navigation/SiIdebarNavigation";
import { navigationList } from "../sidebar-navigation/helpers";
import { profileThunks } from "action-thunks/profile-thunks";

import styles from "./styles.module.scss";
import { tradeGroupThunks } from "action-thunks/tradeGroup-thunks";
import { marketdataThunks } from "action-thunks/marketdata-thunks";
import Notification from "components/notification";

const PageLayoutWrapper = ({ component: Component }) => {
  const { notificationChildren } = useSelector((state) => state.utils);
  const { TGsList } = useSelector((state) => state.tradeGroup);
  const { profileData } = useSelector((state) => state.profile);

  const [activeSidebarItem, setActiveSidebarItem] = useState(null);

  const dispatch = useDispatch();
  const location = useLocation();

  const adminGroup = TGsList?.[0];

  useEffect(() => {
    dispatch(tradeGroupThunks.getTGsListTC());
    dispatch(marketdataThunks.getMarketDataProvidersTC());
    dispatch(marketdataThunks.getTradingPlatformsTC());
    dispatch(tradeGroupThunks.getRiskSettingsMetadataTC());
    dispatch(profileThunks.getProfileTC());
  }, []);

  useEffect(() => {
    const found = navigationList.find(
      (i) =>
        `/${i.destination.split("/")[1]}` ===
        `/${location?.pathname.split("/")[1]}`
    );
    if (found) {
      setActiveSidebarItem(found);
    }
  }, [location]);

  const onSideItemClick = (item) => {
    if (item) {
      setActiveSidebarItem(item);
    }
  };

  return (
    <div className={styles["page-layout-wrapper"]}>
      <SidebarNavigation
        activeSidebarItem={activeSidebarItem}
        onSideItemClick={onSideItemClick}
        profileData={profileData}
      />
      <Component
        adminGroupData={adminGroup}
        onSideItemClick={onSideItemClick}
      />
      {notificationChildren && (
        <div className={styles.notificationList}>
          {notificationChildren.map((child) => (
            <Notification key={child.id} id={child.id}>
              {child.element}
            </Notification>
          ))}
        </div>
      )}
    </div>
  );
};

export default PageLayoutWrapper;
