import { isValidElement } from "react";
import momentTZ from "moment-timezone";

import { constants, separateConstants } from "utilities/constants";
import { helperFunctions } from "utilities/helper-functions";

import styles from "./styles.module.scss";
import ConfirmationModal from "components/requests/modals/confirmation/ConfirmationModal";
import moment from "moment-timezone";

const { summaryData, categoryDataType, contextMenuOptionType } =
  constants;
const { notActiveRequestsStates, actionData, statusData } = separateConstants;

const getAccountSummaryBlock = (extensions) => {
  if (extensions?.noData) {
    return Object.entries(summaryData).map((ext, index) => {
      return (
        <div
          key={`${index}-${ext[0]}`}
          className={styles["this-month-body-item"]}
        >
          <span>{ext[1]}</span>
          <span className="money-block">N/A</span>
        </div>
      );
    });
  }
  if (extensions) {
    return Object.entries(extensions.ext)?.map((ext, index) => {
      if (ext[0] !== "Account" && summaryData[ext[0]])
        return (
          <div
            key={`${index}-${ext[0]}`}
            className={styles["this-month-body-item"]}
          >
            <span>{summaryData[ext[0]]}</span>
            <span className="money-block">
              {ext[0] === "Volume"
                ? ext[1].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : helperFunctions.currencyFormatter().format(ext[1])}
            </span>
          </div>
        );
      return null;
    });
  }
};

const formatStateData = (item, note, formattedStatus) => {
  if (!note && item?.initiator !== "SYSTEM") return formattedStatus;
  if (note?.source === "phone" || item?.initiator === "SYSTEM") {
    let returnValue = null;
    const action = notActiveRequestsStates.includes(item.status)
      ? "canceled"
      : "created";
    const date = momentTZ(item.createdAt).format("MMMM DD, yyyy");
    const time = momentTZ(item.createdAt).format("h:mm A");
    if (note?.source === "phone") {
      returnValue = `This request was ${action} by Support on ${date} at ${time}`;
    } else {
      returnValue = `Note: This request was automatically created by System`;
    }

    return (
      <div>
        <div>{formattedStatus}</div>
        <br />
        <div className="fs-italic">{returnValue}</div>
      </div>
    );
  }
};

const summaryList = (ext = {}, schedule) => {
  const {
    addMarkets = [],
    removeMarkets = [],
    field,
    action,
    oldValue,
    value,
    subject,
    attachments,
  } = ext;

  if (addMarkets.length || removeMarkets.length) {
    const addMarketsList = addMarkets.map((m) => m.market).join(", ");
    const removeMarketsList = removeMarkets.map((m) => m.market).join(", ");

    return (
      <div className="support-table-summary">
        <div>Apply the following changes:</div>
        <ul className="mb-0">
          {addMarketsList && <li>add {addMarketsList}</li>}
          {removeMarketsList && <li>remove {removeMarketsList}</li>}
        </ul>
        <div>
          Due date: {moment.utc(schedule?.duedate).format("YYYY-MM-DD")}
        </div>
      </div>
    );
  }

  if (field) {
    let fieldAction;

    if (Number(value) > Number(oldValue)) {
      fieldAction = `${actionData.INCREASE}`;
    } else if (Number(value) < Number(oldValue)) {
      fieldAction = `${actionData.DECREASE}`;
    }

    return `${fieldAction} from ${
      helperFunctions.currencyFormatter().format(oldValue)?.split(".")[0]
    }
    to ${helperFunctions.currencyFormatter().format(value)?.split(".")[0]}`;
  }

  if (action) {
    return (
      <div>{`${
        actionData[action] || action
      } my account on ${helperFunctions.userTime(
        schedule?.duedate,
        "YYYY-MM-DD"
      )}`}</div>
    );
  }

  if (attachments) {
    return <div className="support-table-summary">{`${subject}`}</div>;
  }
};

const tableFormattedDataForEachElement = (item) => {
  const {
    applicationID,
    sequenceNumber,
    type,
    status,
    note,
    updatedAt,
    createdAt,
    schedule,
    subtype,
    ext,
  } = item;
  let formattedCategory = categoryDataType.CUSTOM;
  let formattedStatus = status;
  let formattedSubtype = subtype || "N/A";
  let formattedFullName = "N/A";

  // additional format for => category column
  if (categoryDataType[type]) {
    formattedCategory = categoryDataType[type];
  }

  // additional format for => type column
  if (formattedCategory === categoryDataType.RISKSETTING) {
    formattedSubtype = schedule?.type === "EOD" ? "EOD" : "Permanent";
  }

  // additional format for => FullName column
  if (applicationID?.personal?.firstName || applicationID?.personal?.lastName) {
    formattedFullName = `${applicationID?.personal?.firstName} ${applicationID?.personal?.lastName}`;
  }

  return {
    updated: updatedAt || "N/A",
    id: sequenceNumber || "N/A",
    traderId: applicationID?.reference?.client || "N/A",
    "Full Name": formattedFullName,
    subgroup: "N/A",
    category: formattedCategory,
    type: formattedSubtype,
    summary: summaryList(ext, schedule, {}) || "N/A",
    state: formatStateData(item, note, formattedStatus),
    created: createdAt || "N/A",
    hiddenData: item,
  };
};

const tableFormattedData = (onlyActive, requestsItemsDataALL) => {
  let RI = requestsItemsDataALL.map((i) => i);

  if (onlyActive) {
    RI = requestsItemsDataALL?.filter(
      (r) => !separateConstants.notActiveRequestsStates.includes(r.status)
    );
  }

  if (RI?.length)
    return RI?.map((item) => tableFormattedDataForEachElement(item));

  return [
    {
      updated: "N/A",
      id: "N/A",
      traderId: "N/A",
      "full name": "N/A",
      subgroup: "N/A",
      category: "N/A",
      type: "N/A",
      summary: "N/A",
      state: "N/A",
      created: "N/A",
    },
    "no-data",
  ];
};

const showInOverlay = (props) => {
  if (props.row) {
    const options = [];
    const {
      row,
      isLastItem,
      showModal,
      hideModal,
      cancelRequest,
      MenuContext,
      TGRequests,
      hiddenData,
    } = props;

    const showCancelRequestItem = () => {
      // First, check the state of the row
      const hidden = hiddenData || row.original.hiddenData;
      const ros = hidden.status?.toLowerCase();
      const sdo = statusData.OPEN.toLocaleLowerCase();
      const sdnm = statusData.NEW_MESSAGE.toLocaleLowerCase();
      const foundRowItemInRequests = TGRequests?.find(
        (r) => r._id === row.original.hiddenData._id
      );

      const isStateValid = () => {
        if (ros === sdo) return true;
        else if (ros === sdnm) return true;
        else if (row.original.state) {
          if (isValidElement(row.original.state)) {
            return (
              row.original.state?.props?.children[0]?.props?.children?.toLowerCase() ===
              statusData.OPEN.toLowerCase()
            );
          } else {
            return (
              row.original.state?.toLowerCase() ===
              statusData.OPEN.toLowerCase()
            );
          }
        }
      };
      if (foundRowItemInRequests && foundRowItemInRequests.initiator === "SYSTEM") {
        return false;
      }

      // If the state is not valid, we can return false early
      if (!isStateValid()) {
        return false;
      }

      return true;
    };
    
    if (showCancelRequestItem()) {
      options.push({
        title: "Cancel request",
        href: contextMenuOptionType.CANCEL_REQUEST,
        onClick: () =>
          showModal(
            <ConfirmationModal
              isApplyButtonRed
              showEditModal
              closeModal={hideModal}
              onApplyClick={cancelRequest}
              item={row}
              labels={{
                title: "Cancel request?",
                body: "Do you really want to cancel this request?",
                buttons: { apply: "Yes", decline: "No" },
              }}
            />
          ),
      });
    }

    if (
      row.original.category === categoryDataType.CUSTOM &&
      row.original.state === statusData.WAITING_FOR_CLIENT
    ) {
      options.push({
        title: 'Mark this request "Resolved"',
        href: contextMenuOptionType.RESOLVE_REQUEST,
        onClick: () =>
          showModal(
            <ConfirmationModal
              showEditModal
              closeModal={hideModal}
              onApplyClick={cancelRequest}
              item={row}
              labels={{
                title: "Confirm request resolution?",
                body: "Do you really want to mark this request as resolved?",
                buttons: { apply: "Yes", decline: "no" },
              }}
            />
          ),
      });
    }

    if (!!options.length)
      return (
        <td className="hover-content">
          <MenuContext
            side={isLastItem && "top"}
            className="row-overlay-menu"
            styleType="zimtra-primary"
            options={options}
          />
        </td>
      );
  }
};

const GOhelpers = {
  getAccountSummaryBlock,
  tableFormattedData,
  showInOverlay,
};

export default GOhelpers;
