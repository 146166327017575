import { useEffect, useState } from "react";
import moment from "moment";
import { Button } from "react-bootstrap";

import ModalConfirmation from "./modalConfirmation";
import { separateConstants } from "utilities/constants";
import {
  checkIfNotValidMarketdataRequest,
  checkTwoMDArraysToIdentity,
  getTotalPriceHandler,
} from "../../../helpers";

const { RequestFormStatusTypes } = separateConstants;

const ConfirmModal = ({
  setDynamicPadding,
  localState,
  dataWasChanged,
  setDataWasChanged,
  requestedMarketsData = { status: RequestFormStatusTypes.COMPLETED },
  blockRequest,
  handleMarketDataRequestModalDateChange,
  primaryEntitlements,
  filterMarketsToTradingPlatform,
  ...props
}) => {
  const [disableButton, setDisableButton] = useState(false);

  const getTotalPrice = (markets) =>
    getTotalPriceHandler(markets, filterMarketsToTradingPlatform);

  //detect height of the confirmation-block to apply additional padding to
  //marketdata block for scrolling requires on small monitors
  // useEffect(() => {
  //   const confBlock = document.getElementsByClassName("confirmation-block");
  //   if (confBlock.length > 0 && confBlock[0].offsetHeight > 1) {
  //     setDynamicPadding(`${confBlock[0].offsetHeight}px`);
  //   }
  //   if (confBlock.length === 0) {
  //     setDynamicPadding(`0px`);
  //   }
  // }, [setDynamicPadding, requestedMarketsData.status, dataWasChanged]);

  //detect if two arrays of entitlements is same and disable button to create request if true
  useEffect(() => {
    const newMarkets = localState?.markets;
    if (
      primaryEntitlements?.length === newMarkets?.length &&
      checkTwoMDArraysToIdentity(newMarkets, primaryEntitlements)
    ) {
      setDisableButton(true);
      setDataWasChanged(false);
    } else {
      setDisableButton(false);
    }
  }, [localState, primaryEntitlements]);

  const handleDateChange = (dateValue) => {
    setDisableButton(
      checkIfNotValidMarketdataRequest(
        dateValue,
        localState.markets,
        primaryEntitlements
      )
    );
    handleMarketDataRequestModalDateChange({ dateValue });
  };

  const renderRequestedPart = () => {
    const isRequestBeingFullfilled =
      requestedMarketsData.status === RequestFormStatusTypes.INPROGRESS ||
      requestedMarketsData.status === RequestFormStatusTypes.ONHOLD;

    return (
      <div className="confirmation-block">
        <div className="left-block">
          <p>
            The changes shown in the tables above are due on &nbsp;
            {moment(localState.effectiveDate).format("YYYY.MM.DD")}.
          </p>
          <p>
            Total Monthly Cost will change from currently{" "}
            {getTotalPrice(primaryEntitlements)} to &nbsp;
            {getTotalPrice(localState?.markets)}.
          </p>
          {isRequestBeingFullfilled && <p>Your request is being fulfilled.</p>}
        </div>
        {!isRequestBeingFullfilled && (
          <div className="confirmation-buttons modify">
            <Button
              className="main-button"
              size="lg"
              variant="primary"
              type="submit"
            >
              Modify/Cancel request{" "}
            </Button>
          </div>
        )}
      </div>
    );
  };

  if (
    !dataWasChanged &&
    requestedMarketsData.status === RequestFormStatusTypes.COMPLETED
  ) {
    return (
      <>
        <hr />
        <p className="market-total">
          Total Monthly Cost: {getTotalPrice(localState?.markets)}
        </p>
      </>
    );
  }

  if (
    (dataWasChanged &&
      requestedMarketsData.status === RequestFormStatusTypes.COMPLETED) ||
    requestedMarketsData.status === RequestFormStatusTypes.BLOCKED
  ) {
    return (
      <ModalConfirmation
        onDateChange={handleDateChange}
        localState={localState}
        disableButton={disableButton}
        requestedMarketsData={requestedMarketsData}
        dataWasChanged={dataWasChanged}
        setDisableButton={setDisableButton}
        primaryEntitlements={primaryEntitlements}
        filterMarketsToTradingPlatform={filterMarketsToTradingPlatform}
        alreadyHadRequest={
          requestedMarketsData.status === RequestFormStatusTypes.OPEN ||
          requestedMarketsData.status === RequestFormStatusTypes.BLOCKED
        }
        {...props}
      />
    );
  }
  if (
    requestedMarketsData.status === RequestFormStatusTypes.OPEN ||
    requestedMarketsData.status === RequestFormStatusTypes.INPROGRESS ||
    requestedMarketsData.status === RequestFormStatusTypes.ONHOLD
  ) {
    return renderRequestedPart();
  }
};

export default ConfirmModal;
