import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import cn from "classnames";
import { useFormik } from "formik";
import { object, number } from "yup";

import { createFormFieldValidation, isRegularCategory } from "./helpers";
import { Modal } from "components/ui-kit/Modal/Modal";
import { Dropdown } from "components/ui-kit/Dropdown";
import { RadioGroup } from "components/ui-kit/Radio";
import { Input } from "components/ui-kit/Input";
import { constants } from "utilities/constants";
import { helperFunctions } from "utilities/helper-functions";

import "./styles.scss";

const { RequestTypes } = constants;

createFormFieldValidation("riskSettingValue");

const CreateRiskSettingsModal = ({
  closeModal,
  updateCategory,
  categories,
  traderIdsList,
  activeCategory,
  updateTraderIds,
  activeTraderIds,
  TGAccountsList,
  submitModal,
}) => {
  const { riskSettingMetadata } =
    useSelector((state) => state.tradeGroup) || [];

  const [selectedRequestDate, setSelectedRequestDate] = useState(null);
  const [isChangedCategory, setChangedCategory] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [activeRiskSetting, setRiskSetting] = useState(null);
  const [eod, setEod] = useState([
    { label: "Permanent", checked: false, value: "perm" },
    { label: "Reset end of day (EOD)", checked: false, value: "eod" },
  ]);
  const [localActiveTraderIds, setLocalActiveTraderIds] =
    useState(activeTraderIds);

  // Update local state when activeTraderIds prop changes
  useEffect(() => {
    setLocalActiveTraderIds(activeTraderIds);
  }, [activeTraderIds]);

  const defaultTraderId =
    localActiveTraderIds?.length === 1 ? localActiveTraderIds[0] : "";

  const currentTraderTradingPlatform = () => {
    let currentTraderID;
    if (!!defaultTraderId) {
      currentTraderID = defaultTraderId;
    } else {
      currentTraderID = localActiveTraderIds[0];
    }
    const foundTrader = TGAccountsList?.find(
      (t) => t.reference.client === currentTraderID
    );
    if (foundTrader) {
      return foundTrader.software;
    }
    return "";
  };

  const platform = currentTraderTradingPlatform();

  const updatedRiskSettingMetadata = riskSettingMetadata
    ?.filter((item) => {
      // Exclude items where default is 0
      if (item.settings.default === 0) return false;
  
      // If availablePlatforms exists, include item only if current platform is available
      if (item.settings.availablePlatforms) {
        return item.settings.availablePlatforms.includes(platform);
      }
  
      // Include the item if availablePlatforms doesn't exist
      return true;
    })
    .map((item) => {
      // Create a new item with the 'value' property
      let newItem = { ...item, value: item.name };
  
      // If platformToLabel exists and matches the current platform, update the label
      if (item.settings.platformToLabel && item.settings.platformToLabel[platform]) {
        newItem.label = item.settings.platformToLabel[platform];
      }
  
      return newItem;
    });
  

  const defaultCategory = categories?.find(
    (category) => category.value === activeCategory.value
  );


  const RiskSettingsSchema = object({
    updatedValue: number("Only positive integer numbers are allowed")
      .required("Required field")
      .positive("Only positive integer numbers are allowed")
      .integer("Only positive integer numbers are allowed")
      .typeError("Only positive integer numbers are allowed")
      .riskSettingValue(activeRiskSetting),
  });

  const formik = useFormik({
    initialValues: {
      updatedValue: "",
    },
    validationSchema: RiskSettingsSchema,
    onSubmit: (values) => handleFormSubmit(values),
  });

  const foundAccount = useMemo(() => {
    if (defaultTraderId.length && TGAccountsList) {
      return TGAccountsList.find((a) => a.reference.client === defaultTraderId);
    }
  }, [TGAccountsList, defaultTraderId]);

  const handleFormSubmit = (values) => {
    localActiveTraderIds.forEach((AT) => {
      const activeTrader =
        localActiveTraderIds.length === 1
          ? foundAccount
          : TGAccountsList.find((a) => a.reference.client === AT);

      const body = {
        type: RequestTypes.RISKSETTING,
        label: activeRiskSetting.label,
        ext: {
          field: activeRiskSetting.name,
          oldValue: activeTrader.riskSettings[activeRiskSetting.name],
          value: values.updatedValue,
        },
        eod: selectedRequestDate.value === "perm" ? false : true,
      };

      submitModal({
        body,
        type: RequestTypes.RISKSETTING,
        traderRef: AT,
      });
    });
  };

  const onTraderIdChange = (selectedTraderId) => {
    setLocalActiveTraderIds([selectedTraderId]);
    updateTraderIds(localActiveTraderIds, true);
  };

  const handleRiskSettingChange = (riskSetting) => {
    setRiskSetting(() =>
      updatedRiskSettingMetadata?.find((item) => item.name === riskSetting)
    );
    setDisabled(false);
    setEod((oldVal) => {
      const newEod = [...oldVal];
      newEod[0].checked = true;
      return newEod;
    });

    document
      .getElementById("category-value-field")
      .dispatchEvent(new Event("change"));
    setChangedCategory(true);
  };

  const handleInputBlur = (e) => {
    const oldVal = formik.values.updatedValue;
    formik.setFieldValue(
      "updatedValue",
      helperFunctions.roundToNearest(
        oldVal,
        isRegularCategory(activeRiskSetting) ? 100 : 10
      )
    );
  };

  useEffect(() => {
    if (isChangedCategory) {
      formik.validateForm();
      setChangedCategory(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangedCategory]);

  useEffect(() => {
    setSelectedRequestDate(() => {
      return eod?.find((item) => item.checked);
    });
  }, [eod]);

  return (
    <div className="modal-window">
      <Modal
        className="risk-settings-modal cabinet-modals"
        overlayClassName="request-overlay"
        styleType="zimtra-primary"
        isOpen={true}
        hideBlur
        hasCloseIcon={false}
        onClickOutside={closeModal}
      >
        <Modal.Header
          className="modal-inner-header"
          onClose={closeModal}
          title={<h2 className="zimtra-h2">New Request</h2>}
        />
        <Modal.Body className="modal-inner-body">
          <div className="d-flex align-items-top modal-row">
            <div className="left-side">Category:</div>
            <div>
              <Dropdown
                options={categories}
                defaultValue={defaultCategory.value}
                className="request-dropdown select-category-dropdown"
                placeholder="Select category"
                onChange={updateCategory}
              />
            </div>
          </div>
          <div className="d-flex align-items-top modal-row">
            <div className="left-side">TraderID:</div>
            {localActiveTraderIds?.length > 1 ? (
              <div className="left-side">{localActiveTraderIds.join(", ")}</div>
            ) : (
              <div>
                <Dropdown
                  options={traderIdsList}
                  defaultValue={defaultTraderId}
                  className="request-dropdown select-category-dropdown"
                  placeholder="Enter TraderID"
                  onChange={(value) => onTraderIdChange(value)}
                />
              </div>
            )}
          </div>
          <hr />
          {!!platform && (
            <div className="mt-4">
              Chosen Platform: {platform}
            </div>
          )}
          <form onSubmit={formik.handleSubmit}>
            <div className="d-flex align-items-top modal-row">
              <Dropdown
                options={updatedRiskSettingMetadata}
                className="request-dropdown select-risk-settings-dropdown"
                placeholder="Select a Risk Settings"
                onChange={handleRiskSettingChange}
              />
            </div>
            <div className="d-flex align-items-top current-price-row modal-row">
              <div className="left-side">current</div>
              <div>
                {activeRiskSetting && foundAccount
                  ? helperFunctions
                      .currencyFormatter()
                      .format(
                        foundAccount.riskSettings?.[activeRiskSetting.name]
                      )
                      .replace(/\D00$/, "")
                  : "N/A"}
              </div>
            </div>
            <div
              className={cn(
                "d-flex align-items-top change-price-row modal-row",
                {
                  "disable-row": disabled,
                }
              )}
            >
              <div className="change-text left-side">change to</div>
              <div
                className={cn("change-value", {
                  "error-field": formik.errors.updatedValue,
                })}
              >
                <Input
                  labelClassName="cabinet-input"
                  variant="zimtra-primary"
                  placeholder="New Value"
                  size="short"
                  id="category-value-field"
                  name="updatedValue"
                  onChange={formik.handleChange}
                  onBlur={handleInputBlur}
                  value={formik.values.updatedValue}
                />
                <Tooltip
                  placement="bottom-end"
                  isClosable={false}
                  anchorSelect={`#anchor-tooltip-id`}
                >
                  i
                  {activeRiskSetting ? (
                    <span>
                      Value is rounded to the nearest increment of &nbsp;
                      {isRegularCategory(activeRiskSetting) ? 100 : 10}
                    </span>
                  ) : null}
                </Tooltip>
                {formik.errors.updatedValue ? (
                  <div className="error-text">{formik.errors.updatedValue}</div>
                ) : null}
              </div>
            </div>
            <div
              className={cn(
                "d-flex align-items-top type-eod-row radio-group-container modal-row",
                {
                  "disable-row": disabled,
                }
              )}
            >
              <RadioGroup
                data={eod}
                onChange={(item) => setSelectedRequestDate(item)}
              />
            </div>
          </form>
          <hr />
        </Modal.Body>
        <Modal.Footer className="modal-inner-footer d-flex justify-content-between">
          <button
            className="main-button"
            disabled={disabled || !formik.isValid}
            onClick={formik.handleSubmit}
          >
            Create
          </button>
          <button className="main-button cancel-button" onClick={closeModal}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreateRiskSettingsModal;
