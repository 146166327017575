import { useState, useEffect, forwardRef } from 'react';
import cn from 'classnames';
import styles from './Input.module.scss';

export const Input = forwardRef(({
  label,
  labelClassName,
  rightLabel,
  hint,
  disabled,
  alertMessage,
  placeholder,
  data,
  size,
  type = 'text',
  serverId,
  variant = 'primary',
  checkedState,
  onChange,
  onRightLabelClick,
  ...props
}) => {
  const [localValue, setLocalValue] = useState(data || '');

  const onChangeHandler = (e) => {
    onChange?.(e, e.currentTarget.value);
    setLocalValue(e.currentTarget.value);
  };

  const onRightLabelClickHandler = (e) => {
    onRightLabelClick?.(e);
  };

  useEffect(() => {
    // if (data) {
      setLocalValue(data);
    // }
  }, [data]);

  const inputId = `input_${crypto.randomUUID()}`;

  return (
    <label
      className={cn([styles[variant]], labelClassName, {
        [styles.disabled]: disabled,
        [styles.short]: size === 'short',
      })}
      htmlFor={inputId}
    >
      {(label || rightLabel) && (
        <div className={styles.labelBlock}>
          <span className={styles.label}>{label}</span>
          {rightLabel && (
            <span onClick={onRightLabelClickHandler} className={styles.rightLabel} role="presentation">
              {rightLabel}
            </span>
          )}
        </div>
      )}
      <div className={styles.wrapper}>
        <input
          type={type}
          className={cn({
            [styles.errorInput]: alertMessage,
            [styles.checkedState]: checkedState,
          })}
          disabled={disabled}
          value={localValue}
          placeholder={placeholder}
          onChange={onChangeHandler}
          id={inputId || serverId}
          {...props}
        />
        <span className={styles.wrapperBorder} />
      </div>
      {(alertMessage || hint) && (
        <div className={`${alertMessage ? styles.errorStyle : styles.hintStyle}`}>
          <span>{alertMessage || (hint || '')}</span>
        </div>
      )}
    </label>
  );
});

Input.displayName = 'Input';
