import { useEffect, useState } from "react";

import "./styles.scss";
import { Modal } from "components/ui-kit/Modal/Modal";
import { Dropdown } from "components/ui-kit/Dropdown";

const CreateNewRequestModal = ({
  closeModal,
  updateCategory,
  categories,
  activeTraderIds,
  traderIdsList,
  updateTraderIds,
  disableTraderDropdown,
}) => {
  const [isDisabled, setDisabled] = useState(true);
  const [activeCategory, setActiveCategory] = useState(null);
  const [localActiveTraderIds, setLocalActiveTraderIds] = useState(activeTraderIds);
  const defaultTraderIds = localActiveTraderIds?.length === 1 ? localActiveTraderIds[0] : "";

  useEffect(() => {
    if (activeTraderIds) {
      setLocalActiveTraderIds(activeTraderIds)
    }
  }, [activeTraderIds])

  useEffect(() => {
    if (localActiveTraderIds?.length && localActiveTraderIds[0] !== null && activeCategory) {
      setDisabled(false);
    } else {
      setDisabled(true)
    }
  }, [localActiveTraderIds, activeCategory])

  const handleCategoryChange = (selectedCategory, subLevel = true) => {
    setActiveCategory(selectedCategory);
    if (subLevel) {
      updateCategory(selectedCategory);
    }
  };

  const onTraderIdChange = (selectedTraderId) => {
      setLocalActiveTraderIds([selectedTraderId]);
      updateTraderIds(localActiveTraderIds, true);
  };

  const onNextClickHandler = () => {
    updateCategory(activeCategory);
    updateTraderIds(localActiveTraderIds, true);
  };

  const formattedTradersList = () => {
    if (traderIdsList) {
      if (activeCategory === "disableAccount") {
        return traderIdsList.filter(t => t.data.status === "AccountEnabled")
      }
      if (activeCategory === "enableAccount") {
        return traderIdsList.filter(t => t.data.status === "AccountSuspended" || t.data.status === "AccountCreated")
      }
      return traderIdsList 
    }
    return null;
  }


  return (
    <div className="modal-window">
      <Modal
        className="new-request-modal cabinet-modals"
        overlayClassName="request-overlay"
        styleType="zimtra-primary"
        isOpen={true}
        hideBlur
        hasCloseIcon={false}
        onClickOutside={closeModal}
      >
        <Modal.Header
          className="modal-inner-header"
          onClose={closeModal}
          title={<h2 className="zimtra-h2">New Request</h2>}
        />
        <Modal.Body className="modal-inner-body">
          <div className="d-flex align-items-top">
            <div className="left-side">Category:</div>
            <div>
              <Dropdown
                options={categories}
                placeHolderClassName="request-dropdown__placeholder"
                className="request-dropdown"
                placeholder="Select category"
                onChange={(selectedCategory) => {
                  handleCategoryChange(selectedCategory, false)
                }
                }
              />
            </div>
          </div>
          <div className="d-flex align-items-top">
            <div className="left-side">TraderID:</div>
            {activeTraderIds?.length > 1 ? (
              <div className="left-side">
                {activeTraderIds.join(", ")}
              </div>
            ) : (
              <div>
                <Dropdown
                  disabled={disableTraderDropdown}
                  options={formattedTradersList()}
                  placeHolderClassName="request-dropdown__placeholder"
                  defaultValue={defaultTraderIds}
                  // className="request-dropdown"
                  placeholder="Enter TraderID"
                  onChange={(selectedTraderId) =>
                    onTraderIdChange(selectedTraderId)
                  }
                />
              </div>
            )}
          </div>
          <hr />
        </Modal.Body>
        <Modal.Footer className="modal-inner-footer d-flex justify-content-between">
          <button
            disabled={isDisabled}
            onClick={onNextClickHandler}
            className="main-button"
          >
            Next
          </button>
          <button className="main-button cancel-button" onClick={closeModal}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreateNewRequestModal;
