import { types } from "../reducers/types";

const {
  auth: { SET_CURRENT_USER, SET_AUTH },
  utils: { SET_IS_LOADING, GET_ERRORS, SET_NOTIFICATION_CHILDREN, REMOVE_NOTIFICATION },
  profile: { SET_PROFILE },
  tg: { SET_TGs_LIST, SET_AGENCY_LIST, SET_TG_ACCOUNTS_LIST, SET_TG_BALANCE, SET_TG_REQUESTS_LIST, SET_RS_METADATA, ADD_TG_REQUEST, UPDATE_REQUEST_LIST },
  docs: { REMOVE_DOCUMENT_FROM_STORE, SET_CURRENT_DOCUMENT, SET_LIST_OF_DOCUMENTS, SET_ETB_DOCUMENT, SET_FRESHEST_ETB_DOCUMENT },
  marketdata: { SET_MARKETDATA_PROVIDERS, SET_MARKET_BUNDLES, SET_TRADING_PLATFORMS },
  statements: { SET_STATEMENTS },
} = types;

//auth actions
export const setCurrentUserAC = (payload) => ({
  type: SET_CURRENT_USER,
  payload,
});
export const setIsAuthenticatedAC = (payload) => ({ type: SET_AUTH, payload });

//utils actions
export const setLoadingAC = (payload) => ({ type: SET_IS_LOADING, payload });
export const setNotificationChildrenAC = (payload) => ({ type: SET_NOTIFICATION_CHILDREN, payload });
export const removeNotificationAC = (id) => ({ type: REMOVE_NOTIFICATION, payload: id });
export const setErrorAC = (payload) => ({ type: GET_ERRORS, payload });

//profile actions
export const setProfileAC = (payload) => ({ type: SET_PROFILE, payload });

//tradeGroup
export const setTGsListAC = (payload) => ({ type: SET_TGs_LIST, payload });
export const setAgencyListAC = (payload) => ({ type: SET_AGENCY_LIST, payload });
export const setTGAccountsListAC = (payload) => ({ type: SET_TG_ACCOUNTS_LIST, payload });
export const setTGBalanceAC = (payload) => ({ type: SET_TG_BALANCE, payload });
export const setTGRequestsListAC = (payload) => ({ type: SET_TG_REQUESTS_LIST, payload });
export const setRiskSettingsMetadataAC = (payload) => ({ type: SET_RS_METADATA, payload });
export const addTGRequestAC = (payload) => ({ type: ADD_TG_REQUEST, payload });
export const updateAccRequestAC = (payload) => ({ type: UPDATE_REQUEST_LIST, payload });

//marketdata
export const setMarketdataProvidersAC = (payload) => ({ type: SET_MARKETDATA_PROVIDERS, payload });
export const setMarketBundleAC = (payload) => ({ type: SET_MARKET_BUNDLES, payload });
export const setTradingPlatformAC = (payload) => ({ type: SET_TRADING_PLATFORMS, payload });

//documents actions
export const setListOfDocumentsAC = (payload) => ({ type: SET_LIST_OF_DOCUMENTS, payload });
export const removeDocumentFromStoreAC = (payload) => ({ type: REMOVE_DOCUMENT_FROM_STORE, payload });
export const setCurrentDocumentAC = (payload) => ({ type: SET_CURRENT_DOCUMENT, payload });
export const setETBDocumentAC = (payload) => ({ type: SET_ETB_DOCUMENT, payload });
export const setFreshestETBDocumentAC = (payload) => ({ type: SET_FRESHEST_ETB_DOCUMENT, payload });

//statements actions
export const setStatementsListAC = (payload) => ({ type: SET_STATEMENTS, payload });