import cn from "classnames";
import styles from "./Overlay.module.scss";

export const Overlay = ({
  children,
  onClick,
  isOpen,
  hideBlur = false,
  ...rest
}) => {
  if (!isOpen) return null;
  return (
    <div data-testid="overlay" {...rest}>
      <div
        id="overlay-root"
        onClick={onClick}
        className={cn(styles.overlay, styles.colored)}
        aria-hidden="true"
      />
      {!hideBlur && <div className={cn(styles.overlay, styles.blur)} />}
      {children}
    </div>
  );
};

Overlay.displayName = "Overlay";
