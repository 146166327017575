import { instance, setHeader } from "./auth-api";

const profileUri = "/users";

export const profileAPI = {
  getProfile: async () => {
    const response = await instance.get(`${profileUri}/myself`, { headers: setHeader() });
    return response;
  }
}

