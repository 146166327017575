import { Children, isValidElement } from "react";

function getComponentName(component) {
  if (typeof component === "string") {
    return component;
  }
  return component?.displayName || component?.name || "UnknownComponent";
}

export const useChildren = (children, { allowed }) => {
  Children.forEach(children, (child) => {
    if (
      allowed &&
      isValidElement(child) &&
      typeof child !== "string" &&
      !allowed.includes(getComponentName(child.type))
    ) {
      throw new Error(`Children should be of type "${allowed.join('", "')}".`);
    }
  });
};
