import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";

import { constants } from "utilities/constants";
import { ModalContext } from "components/ui-kit/modal-context/ModalContext";
import { tradeGroupThunks } from "action-thunks/tradeGroup-thunks";
import { getMarketsToSend } from "../../../helpers";

const ModalActions = ({
  disableButton,
  localState,
  handleDiscardMarketDataRequestModal,
  filterMarketsToTradingPlatform,
  setDisableButton,
  defaultTraderIds,
}) => {
  const dispatch = useDispatch();
  const { hideModal } = useContext(ModalContext);
  const { TGAccountsList } = useSelector((state) => state.tradeGroup);
  const { RequestTypes } = constants;

  const onDiscardClick = (withDiscardingPrimaryButton) => {
    hideModal();
    setDisableButton(false);
    handleDiscardMarketDataRequestModal({ withDiscardingPrimaryButton });
  };

  const onOkayClick = async (date, change) => {
    let body = {
      ext: {
        addMarkets: getMarketsToSend({
          markets: localState.addMarkets,
          filterMarketsToTradingPlatform,
        }),
        removeMarkets: getMarketsToSend({
          markets: localState.removeMarkets,
          filterMarketsToTradingPlatform,
        }),
      },
    };

    if (change) {
      body = { ...body, date };
      // dispatch(
      //   updateChangeMarketsRequestTC(
      //     currentAccount._id,
      //     requestedMarketsData._id,
      //     body,
      //     false,
      //     "unblock"
      //   )
      // );
    } else {
      body = { ...body, type: RequestTypes.MARKETDATA, duedate: date };
      // statisticAPI.sendStatUsage(currentAccount._id, {
      //   page: "Create request: Market Data",
      // });
      if (defaultTraderIds) {
        const promises = defaultTraderIds?.map((tdi) => {
          const foundTrader = TGAccountsList.find(
            (a) => a.reference.client === tdi
          );
          if (foundTrader) {
            return dispatch(
              tradeGroupThunks.createDataRequestTC({
                accID: foundTrader._id,
                body,
                traderID: foundTrader.reference.client,
              })
            );
          }
          return Promise.resolve(); // In case foundTrader is not found, we resolve the promise immediately.
        });

        await Promise.all(promises);

        dispatch(
          tradeGroupThunks.getTGAccountsListTC([
            "marketData",
            "openMDrequest",
            "accountsOnly",
          ])
        );
      }
    }

    onDiscardClick(true);
  };

  return (
    <div className="confirmation-buttons">
      <Button
        className="main-button apply-changes-button"
        disabled={disableButton}
        onClick={() => onOkayClick(localState.effectiveDate)}
      >
        OK
      </Button>
      <Button
        className="main-button discard-changes-button"
        onClick={() => onDiscardClick(true)}
      >
        Cancel
      </Button>
    </div>
  );
};

export default ModalActions;
