import { useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import cn from "classnames";

import { Modal } from "components/ui-kit/Modal/Modal";
import { tradeGroupThunks } from "action-thunks/tradeGroup-thunks";
import styles from "./styles.module.scss";
import "./global.scss";
import DateInput from "components/ui-kit/date-input/DateInput";
import DateRadio from "components/ui-kit/DateRadio/DateRadio";

const OnboardingEffectiveDateModal = ({ item, closeModal }) => {
  // Initialize the state with UTC dates
  const [currentItem, setCurrentItem] = useState({
    ...item.item,
    effectiveDate: moment.utc(item.item.firstEnableRequest.schedule.duedate).startOf('day').toDate(),
  });
  const [startTradeValueType, setStartTradeValueType] = useState(null);

  const dispatch = useDispatch();

  const onDateChange = (e) => {
    const selectedDate = moment.utc(e.target.value).startOf('day').toDate();
    setStartTradeValueType("date");
    setCurrentItem({ ...currentItem, effectiveDate: selectedDate });
  };

  const onSubmitClick = (e) => {
    e.preventDefault();
    let date = currentItem.effectiveDate;

    if (startTradeValueType === "soon") {
      date = moment.utc().startOf('day').toDate();
    }

    if (moment.utc(date).isSame(moment.utc(item.item.firstEnableRequest.schedule.duedate), 'day')) {
      closeModal();
      return;
    }

    dispatch(
      tradeGroupThunks.updateRequestStatusTC({
        requestID: currentItem.firstEnableRequest._id,
        body: { duedate: date },
      })
    );

    closeModal();
  };

  return (
    <div className="modal-window">
      <Modal
        className="cabinet-modals"
        overlayClassName="request-overlay"
        styleType="zimtra-primary"
        isOpen={true}
        hideBlur
        hasCloseIcon={false}
        onClickOutside={closeModal}
      >
        <Modal.Header
          className="modal-inner-header"
          onClose={closeModal}
          title={
            <h2 className="zimtra-h2">
              {currentItem?.fullName} - When to enable?
            </h2>
          }
        />
        <Modal.Body
          className={cn("modal-inner-body", styles["onboarding-modal-body"])}
        >
          <DateRadio
            label={
              <div className="d-flex align-items-center calendar-container modal-row">
                <DateInput
                  name="start"
                  type="date"
                  value={moment.utc(currentItem.effectiveDate).format("YYYY-MM-DD")}
                  min={moment.utc().startOf("day").format("YYYY-MM-DD")}
                  max={moment.utc().add(1, "year").startOf("day").format("YYYY-MM-DD")}
                  onChange={onDateChange}
                />
              </div>
            }
            checked={startTradeValueType === "date"}
            onChangeHandler={() => setStartTradeValueType("date")}
            id="date"
          />
          <DateRadio
            label="as soon as possible"
            checked={startTradeValueType === "soon"}
            onChangeHandler={() => setStartTradeValueType("soon")}
            id="soon"
          />
        </Modal.Body>
        <Modal.Footer
          className={cn(
            "modal-inner-footer",
            styles["onboarding-modal-footer"]
          )}
        >
          <button
            onClick={onSubmitClick}
            disabled={!startTradeValueType}
            className="main-button"
          >
            Confirm
          </button>
          <button className="main-button cancel-button" onClick={closeModal}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default OnboardingEffectiveDateModal;
