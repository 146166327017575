import moment from "moment-timezone";
import { separateConstants } from "utilities/constants";

const { onboardingModalTypes, accountStatusTypes } = separateConstants;

const formattingActionText = (obj, onRowTextClickHandler) => {
  const { type, text } = obj;

  if (type === "nothing") {
    return <span className="regular-text greyed-text">{text}</span>;
  }
  if (type === "modal") {
    return (
      <span
        onClick={() => onRowTextClickHandler(obj)}
        className="regular-text module-text"
      >
        {text}
      </span>
    );
  }
};

const tableFormattedDataForEachElement = (item, onRowTextClickHandler) => {
  const {
    personal,
    createdAt,
    password,
    statusUpdatedAt,
    firstEnableRequest,
    software,
    marketDataRequest,
    status,
  } = item;

  const formattedData = moment(createdAt).format("YYYY-MM-DD");
  let formattedCurrentState = "N/A";
  let formattedActions = "N/A";
  let formattedCreds = formattingActionText({ type: "nothing", text: "N/A" });

  const currentState = () => {
    if (status === accountStatusTypes.INCOMPLETE && !!software) {
      return "notApproved";
    }
    if (status === accountStatusTypes.INCOMPLETE && !software) {
      return "tpNotSelected";
    }
    if (status === accountStatusTypes.APPROVED && software) {
      return "tpPending";
    }
    if (
      status === accountStatusTypes.ACCOUNT_CREATED &&
      software === "Sterling" &&
      !!marketDataRequest
    ) {
      return "sterlingAgreementsNotFilled";
    }
    if (status === accountStatusTypes.ACCOUNT_CREATED && marketDataRequest) {
      return "pendingMD";
    }
    if (
      status === accountStatusTypes.ACCOUNT_CREATED &&
      firstEnableRequest &&
      !firstEnableRequest.schedule?.duedate
    ) {
      return "selectStartDate";
    }
    if (
      status === accountStatusTypes.ACCOUNT_CREATED &&
      firstEnableRequest &&
      firstEnableRequest.schedule?.duedate
    ) {
      return "enableOn";
    }
    if (status === accountStatusTypes.ACCOUNT_ENABLED) {
      return "enabledOn";
    }
  };

  switch (currentState()) {
    case "notComplete":
      formattedActions = "Awaiting the trader to submit the application";
      formattedCurrentState = "Application not completed";
      break;
    case "notApproved":
      formattedActions = "Contact Zimtra Support Team";
      formattedCurrentState = "Application not approved";
      break;
    case "tpNotSelected":
      formattedActions = formattingActionText(
        {
          type: "modal",
          modalType: onboardingModalTypes.TRADING_PACKAGE,
          text: "Select trading package options",
          item,
        },
        onRowTextClickHandler
      );
      formattedCurrentState = "Trading package options not selected";
      break;
    case "tpPending":
      formattedActions = formattingActionText({
        type: "nothing",
        text: "Nothing. Zimtra is working on it",
      });
      formattedCurrentState = "Pending trading platform";
      break;
    case "sterlingAgreementsNotFilled":
      formattedActions = formattingActionText(
        {
          type: "modal",
          modalType: onboardingModalTypes.EXCHANGE_AGREEMENTS,
          text: "Fill out exchange agreements with Sterling",
          item,
        },
        onRowTextClickHandler
      );
      formattedCurrentState = "Exchange agreements not filled out";
      break;
    case "pendingMD":
      formattedActions = formattingActionText({
        type: "nothing",
        text: "Nothing. Zimtra is working on it",
      });
      formattedCurrentState = "Pending market data";
      break;
    case "selectStartDate":
      formattedActions = formattingActionText(
        {
          type: "modal",
          modalType: onboardingModalTypes.EFFECTIVE_DATE,
          text: "Select a start date",
          item,
        },
        onRowTextClickHandler
      );
      formattedCurrentState = "Start date not selected";
      break;
    case "enableOn":
      formattedActions = (
        <span>
          To change this date &nbsp;
          {formattingActionText(
            {
              type: "modal",
              modalType: onboardingModalTypes.EFFECTIVE_DATE,
              text: "click here",
              item,
            },
            onRowTextClickHandler
          )}
        </span>
      );
      formattedCurrentState = `To be enabled on ${moment
        .utc(firstEnableRequest.schedule?.duedate)
        .format("MMMM DD, YYYY")}`;
      break;
    case "enabledOn":
      formattedActions = formattingActionText({
        type: "nothing",
        text: "Ready to trade!",
      });
      formattedCurrentState = `Enabled on ${moment(statusUpdatedAt).format(
        "MMMM DD, YYYY"
      )}`;
      break;

    default:
      formattedCurrentState = "N/A";
      break;
  }

  if (password) {
    formattedCreds = formattingActionText(
      {
        type: "modal",
        modalType: onboardingModalTypes.CREDS,
        text: "See credentials",
        item,
      },
      onRowTextClickHandler
    );
  }

  return {
    "Full Name": `${personal.firstName} ${personal.lastName}` || "N/A",
    email: personal.email || "N/A",
    "Date Created": formattedData || "N/A",
    // Subgroup: "N/A",
    "Current State": formattedCurrentState || "N/A",
    "My Necessary Action": formattedActions || "N/A",
    "Trading Platform Credentials": formattedCreds || "N/A",
  };
};

const tableFormattedData = (onboardingItems, onRowTextClickHandler) => {
  if (onboardingItems?.length) {
    return onboardingItems?.map((item) =>
      tableFormattedDataForEachElement(item, onRowTextClickHandler)
    );
  }

  return [
    {
      "Full Name": "N/A",
      email: "N/A",
      "Date Created": "N/A",
      Subgroup: "N/A",
      "Current State": "N/A",
      "My Necessary Action": "N/A",
      "Trading Platform Credentials": "N/A",
    },
    "no-data",
  ];
};

const onboardingHelpers = {
  tableFormattedData,
};

export default onboardingHelpers;
