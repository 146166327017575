import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";

import GOhelpers from "./helpers";
import { helperFunctions } from "utilities/helper-functions";
import TableComponent from "components/ui-kit/react-table/TableComponent";
import { MenuContext } from "components/ui-kit/Menu/index";
import { supportTableUtils } from "components/ui-kit/react-table/supportTableUtils";
import { NewRequest } from "components/requests/NewRequest";
import { tradeGroupThunks } from "action-thunks/tradeGroup-thunks";
import { ModalContext } from "components/ui-kit/modal-context/ModalContext";
import { constants, separateConstants } from "utilities/constants";
import { navigationList } from "components/sidebar-navigation/helpers";
import SupportStateController from "components/ui-kit/state-controller/SupportStateController";

import styles from "./styles.module.scss";
import "./go-global-table.scss";

const { getAccountSummaryBlock, tableFormattedData, showInOverlay } = GOhelpers;
const { categoryDataType } = constants;

const GroupOverview = ({ adminGroupData, onSideItemClick }) => {
  const { TGBalance, TGRequestsList, TGAccountsList } = useSelector(
    (state) => state.tradeGroup
  );
  const { isLoading } = useSelector((state) => state.utils);

  const TGRequests = useMemo(() => {
    return TGRequestsList !== null
      ? TGRequestsList.filter(
          (r) => !separateConstants.notActiveRequestsStates.includes(r.status)
        )
      : null;
  }, [TGRequestsList]);

  const { showModal, hideModal } = useContext(ModalContext);
  const navigate = useNavigate();

  const [localExtensions, setLocalExtension] = useState(null);
  const [visibleItems, setVisibleItems] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      TGRequests !== null &&
      TGRequests.length > 0 &&
      visibleItems === 0
    ) {
      setVisibleItems(TGRequests.length > 10 ? 10 : TGRequests.length);
    }
  }, [TGRequests, visibleItems]);

  useEffect(() => {
    const fetchRequestsPeriodically = () => {
      if (adminGroupData) {
        dispatch(
          tradeGroupThunks.getTGRequestsListTC(adminGroupData._id, true)
        );
      }
    };

    const fetchInitialData = () => {
      if (adminGroupData) {
        if (TGRequests === null) {
          dispatch(tradeGroupThunks.getTGRequestsListTC(adminGroupData._id));
        }
        if (TGBalance === null) {
          dispatch(tradeGroupThunks.getTGBalanceTC(adminGroupData._id));
        }
        if (TGAccountsList === null) {
          dispatch(
            tradeGroupThunks.getTGAccountsListTC([
              "openMDrequest",
              "marketData",
              "firstEnableRequest",
              "riskSettings",
            ])
          );
        }
      }
    };

    fetchInitialData();

    const intervalId = setInterval(fetchRequestsPeriodically, 3000);

    return () => clearInterval(intervalId);
  }, [adminGroupData, dispatch]);

  useEffect(() => {
    if (localExtensions === null) {
      setLocalExtension({ noData: true });
    }
  }, [localExtensions]);

  useEffect(() => {
    if (TGBalance !== null) {
      setLocalExtension((oldState) => ({
        ...oldState,
        TGBalance: TGBalance,
        noData: false,
      }));
    }
  }, [TGBalance]);

  const setDateFormat = useMemo(() => {
    if (localExtensions) {
      if (localExtensions?.TGBalance?.updatedAt) {
        return helperFunctions.transformToStepDateView(
          localExtensions?.TGBalance?.updatedAt
        );
      } else {
        return "N/A";
      }
    }
  }, [localExtensions]);

  const renderAccountSummary = useMemo(() => {
    if (localExtensions) {
      const ext = { ...localExtensions, ext: localExtensions.TGBalance };
      return getAccountSummaryBlock(ext);
    }
  }, [localExtensions]);

  const cancelRequest = (data) => {
    const itemData = data.original.hiddenData;
    const foundRequest = TGRequests?.find((r) => r._id === itemData._id);

    if (foundRequest) {
      dispatch(
        tradeGroupThunks.updateRequestStatusTC({
          requestID: foundRequest?._id,
          action: "cancel",
        })
      );
    }
  };

  const showInOverlayHandler = (row, isLastItem) => {
    return showInOverlay({
      row,
      isLastItem,
      showModal,
      hideModal,
      cancelRequest,
      MenuContext,
      TGRequests,
    });
  };

  const onInfoClick = () => {
    const fountNav = navigationList.find((n) => n.id === "faq");
    if (fountNav) onSideItemClick(fountNav);
    navigate(fountNav.destination);
  };

  return (
    <div className={cn("page-wrapper", styles["account-overview-wrapper"])}>
      <div className={styles["account-overview-header"]}>
        <h3>Group Overview</h3>
        <span>Name: {adminGroupData?.name}</span>
        <span>updated on {setDateFormat}</span>
      </div>
      <div className={styles["account-summary-block"]}>
        <h6 className={styles["account-title"]}>
          So far this month
          <img
            src={`${process.env.PUBLIC_URL}/icons/info.svg`}
            alt="info"
            onClick={onInfoClick}
          />
        </h6>
        <div className={styles["account-summary-body"]}>
          {renderAccountSummary}
        </div>
      </div>
      {TGRequests && (
        <div className={styles["account-overview-table"]}>
          <NewRequest
            adminGroupData={adminGroupData}
            isActiveRequestsTable
            renderFullListAfterRequest={false}
            filteredCategoriesArray={[categoryDataType.MARKETDATA]}
          />
          <div className={styles["account-overview-table-body"]}>
            <TableComponent
              data={tableFormattedData(true, TGRequests.slice(-visibleItems))}
              isLoading={isLoading}
              dateColumns={["created", "updated"]}
              formattingForDateColumns={supportTableUtils.tableTimeFormat}
              showSearch={false}
              showRowOverlay={showInOverlayHandler}
              //   setCellClassName={setCellClassName}
              itemsToUseSorting={[
                "id",
                "category",
                "type",
                "subgroup",
                "fullname",
                "traderid",
                "state",
                "updated",
                "created",
              ]}
            />
          </div>
          <SupportStateController
            setVisibleItems={setVisibleItems}
            visibleItems={visibleItems}
            items={TGRequests}
          />
        </div>
      )}
    </div>
  );
};

export default GroupOverview;
