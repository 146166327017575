import { useMemo, useState } from "react";
import { Modal } from "components/ui-kit/Modal/Modal";
import cn from "classnames";

import { Input } from "components/ui-kit/Input";
import { helperFunctions } from "utilities/helper-functions";
import { Dropdown } from "components/ui-kit/Dropdown";
import MarketDataPageModal from "components/requests/modals/marketdata-modal/MarketDataPageModal";
import { useDispatch, useSelector } from "react-redux";
import { tradeGroupThunks } from "action-thunks/tradeGroup-thunks";

import styles from "./styles.module.scss";
import "./global.scss";

const OnboardingTradingPackageModal = ({ item, closeModal }) => {
  const { tradingPlatforms, marketdataProviders } = useSelector(
    (state) => state.marketdata
  );
  const { TGAccountsList } = useSelector((state) => state.tradeGroup);

  const dispatch = useDispatch();

  const [overnightIsActive, setOvernightIsActive] = useState(false);
  const [activeTP, setActiveTP] = useState(null);
  const [onboardingLocalState, setOnboardingLocalState] = useState({
    day_buying_power: null,
    max_loss: null,
    overnight: null,
  });
  const [md, setMD] = useState(null);
  const [errors, setErrors] = useState({});

  const { reference, personal, _id } = item.item;

  const filteredTPs = tradingPlatforms?.filter((tp) => tp.active).sort((a,b) => a.order - b.order);

  const handleFormSubmit = () => {
    const foundTP = filteredTPs.find((tp) => tp.reference === activeTP);
    const marketData = md
      .map((m) => {
        const foundMD = marketdataProviders.find((mdp) => mdp.name === m);
        if (foundMD) return { group: foundMD.group, market: foundMD.name };
        return null;
      })
      .filter((i) => i);
    const body = {
      clientID: _id,
      software: foundTP.software,
      softwareType: foundTP.softwareType,
      agreement: {
        rules: {
          initialBuyingPower: onboardingLocalState.day_buying_power,
          maximumDailyLoss: onboardingLocalState.max_loss,
        },
        marketData,
      },
    };
    if (onboardingLocalState.overnight) {
      body.agreement.rules.approvedOvernightBuyingPower =
        onboardingLocalState.overnight;
    }

    closeModal();
    dispatch(tradeGroupThunks.createTradingPackageTC(body));
  };

  const seIsButtonDisabled = () => {
    const hasErrors = Object.values(errors).some((error) => error !== null);
    const hasRequiredValues =
      onboardingLocalState.day_buying_power !== null &&
      onboardingLocalState.max_loss !== null &&
      !!md?.length &&
      activeTP !== null &&
      (!overnightIsActive || onboardingLocalState.overnight !== null);
    return hasErrors || !hasRequiredValues;
  };

  const handleInputBlur = (e) => {
    const { name, value } = e.target;

    let validatedValue = helperFunctions.validateRiskSettingValue(value, {
      name,
    });
    let error = null;

    // Additional error handling based on value
    if (helperFunctions.isRegularCategory({ name }) && value > 1e8) {
      error = "Maximum allowed value: 100,000,000";
    } else if (!helperFunctions.isRegularCategory({ name }) && value > 1e7) {
      error = "Maximum allowed value: 10,000,000";
    }

    if (error) {
      validatedValue = null;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]:
        error || (typeof validatedValue === "string" ? validatedValue : null),
    }));

    if (validatedValue !== null) {
      setOnboardingLocalState((prevState) => ({
        ...prevState,
        [name]: typeof validatedValue === "string" ? value : validatedValue,
      }));
    }
  };

  const onRSChangeHandler = (e) => {
    const { name, value } = e.target;
    setOnboardingLocalState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const formattedTradingPlatformList = useMemo(() => {
    if (filteredTPs) {
      return filteredTPs
        .filter((i) => i.reference !== "das_web_extra")
        .map((item) => ({
          value: item.reference,
          label: item.name,
        }));
    }
  }, [filteredTPs]);

  const handleOvernightChange = (e) => {
    setOvernightIsActive((prevState) => {
      if (prevState) {
        setOnboardingLocalState((prevState) => ({
          ...prevState,
          overnight: 0,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          overnight: null,
        }));
        return false;
      }
      return true;
    });
  };

  const copyLocalState = (MDLocalState) => {
    if (MDLocalState) {
      setMD(MDLocalState.markets);
    }
  };

  const TPChangeHandler = (value) => {
    setMD(null);
    setActiveTP(value);
    setMD(null);
  };

  const renderMDPageModal = useMemo(() => {
    if (activeTP) {
      return (
        <div>
          <MarketDataPageModal
            onboardingClient
            copyLocalState={copyLocalState}
            activeTP={activeTP}
            defaultTraderIds={[reference.onboardingID]}
            TGAccountsList={TGAccountsList}
            closeModal={closeModal}
          />
        </div>
      );
    } else {
      return (
        <p className="greyed-text">
          Please specify a trading platform to select market data
        </p>
      );
    }
  }, [activeTP, reference, TGAccountsList, closeModal]);

  return (
    <div className="modal-window">
      <Modal
        className="new-request-modal-PE cabinet-modals"
        overlayClassName="request-overlay"
        styleType="zimtra-primary"
        isOpen={true}
        hideBlur
        hasCloseIcon={false}
        onClickOutside={closeModal}
      >
        <Modal.Header
          className="modal-inner-header"
          onClose={closeModal}
          title={
            <h2 className="zimtra-h2">
              {`${personal.firstName} ${personal.lastName}`} - Trading package
              options
            </h2>
          }
        />
        <Modal.Body
          className={cn("modal-inner-body", styles["onboarding-modal-body"])}
        >
          <form>
            <h4 className={styles.title}>risk settings</h4>
            <div className="d-flex flex-col gap-20">
              <div className={styles.riskItem}>
                <span>Day Buying Power</span>
                <Input
                  labelClassName="cabinet-input"
                  variant="zimtra-primary"
                  placeholder="Enter a value"
                  id="OnboardingPM_DBP"
                  name="day_buying_power"
                  onChange={(e) =>
                    onRSChangeHandler(e, { name: "day_buying_power" })
                  }
                  onBlur={handleInputBlur}
                  value={onboardingLocalState.day_buying_power}
                  alertMessage={errors.day_buying_power}
                />
              </div>
              <div className={styles.riskItem}>
                <span>Max Loss</span>
                <Input
                  labelClassName="cabinet-input"
                  variant="zimtra-primary"
                  placeholder="Enter a value"
                  id="OnboardingPM_ML"
                  name="max_loss"
                  onChange={(e) => onRSChangeHandler(e, { name: "max_loss" })}
                  onBlur={handleInputBlur}
                  value={onboardingLocalState.max_loss}
                  alertMessage={errors.max_loss}
                />
              </div>
              <div className={styles.riskItem}>
                <label className="main-checkbox">
                  <input
                    checked={overnightIsActive}
                    onChange={handleOvernightChange}
                    type="checkbox"
                  />
                  <span className="checkmark"></span>
                  Overnight Exposure
                </label>
                <div
                  className={cn(styles.overnight, {
                    [styles.disabled]: !overnightIsActive,
                  })}
                >
                  <Input
                    disabled={!overnightIsActive}
                    labelClassName="cabinet-input"
                    variant="zimtra-primary"
                    placeholder="Enter a value"
                    id="OnboardingPM_OBP"
                    name="overnight"
                    onChange={(e) =>
                      onRSChangeHandler(e, { name: "overnight" })
                    }
                    onBlur={handleInputBlur}
                    value={onboardingLocalState.overnight}
                    alertMessage={errors.overnight}
                  />
                </div>
              </div>
            </div>
            <hr />
            <h4 className={styles.title}>trading platform</h4>
            <div className={styles.riskItem}>
              <span>Trading Platform</span>
              <div className="d-flex align-items-top modal-row">
                <Dropdown
                  options={formattedTradingPlatformList}
                  className="request-dropdown select-risk-settings-dropdown"
                  placeholder="Select a Trading Platform"
                  onChange={(value) => TPChangeHandler(value)}
                />
              </div>
            </div>
            <hr />
            <h4 className={styles.title}>market data</h4>
            {renderMDPageModal}
          </form>
        </Modal.Body>
        <Modal.Footer className="modal-inner-footer d-flex justify-content-between">
          <button
            disabled={seIsButtonDisabled()}
            onClick={handleFormSubmit}
            className="main-button"
          >
            Confirm
          </button>
          <button className="main-button cancel-button" onClick={closeModal}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default OnboardingTradingPackageModal;
