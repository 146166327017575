import { useState } from 'react';
import cn from 'classnames';
import styles from './Radio.module.scss';

export const Radio = ({
  label,
  checked,
  disabled,
  onChangeHandler,
  ...props
}) => {
  const inputId = `radio_${crypto.randomUUID()}`;

  return (
    <label
      htmlFor={inputId}
      className={cn(styles.radioButtonItem, {
        [styles['disabled']]: !!disabled,
      })}
    >
      <input
        type="radio"
        className={styles['radio-input']}
        name={label}
        onChange={onChangeHandler}
        checked={checked}
        id={inputId}
        {...props}
      />
      <span>{label}</span>
    </label>
  );
};

export const RadioGroup = ({
  data,
  itemDirection,
  onChange,
}) => {
  const [localData, setLocalData] = useState(data);

  const onChangeHandler = ({
    currentTarget,
  }) => {
    const newLocalData = localData.map((i) => ({
      ...i,
      checked: i.label === currentTarget.name ? currentTarget.checked : false,
    }));
    setLocalData(newLocalData);
    const checkedItem = newLocalData.find((i) => i.checked);
    if (checkedItem) {
      onChange?.(checkedItem);
    }
  };

  return (
    <div
      className={cn(styles.radioButtonBlock, {
        [styles[itemDirection]]: !!itemDirection,
      })}
    >
      {localData.map((item) => (
        <Radio
          key={crypto.randomUUID()}
          onChangeHandler={onChangeHandler}
          {...item}
        />
      ))}
    </div>
  );
};
