import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import momentTZ from "moment-timezone";

import { Spinner } from "react-bootstrap";
import StatementsStateController from "./state-controller/StatementsStateController";
import { statementsThunks } from "action-thunks/statements-thunk";

import styles from "./styles.module.scss";

const MonthlyStatements = ({adminGroupData}) => {
  const [statementsRequested, setStatementsRequested] = useState(false);
  const [hoveredItemId, setHoveredItemId] = useState(null);

  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.utils);
  const { statements } = useSelector((state) => state.statements);

  useEffect(() => {
    if (statements === null && adminGroupData?._id && !statementsRequested) {
      dispatch(statementsThunks.getStatementsListTC(adminGroupData._id));
      setStatementsRequested(true);
    }
  }, [statements, adminGroupData, statementsRequested, dispatch]);

  const onDownloadClick = (file) => {
    dispatch(statementsThunks.getStatementFileTC(adminGroupData?._id, file));
  };

  const renderedStatements = () => {
    if (statements?.length) {
      const statementsToRender = statements.map((s) => {
        const daysDifference = momentTZ().diff(s.updatedAt, "days");
        let formattedDate = momentTZ(s.updatedAt).format("YYYY-MM-DD H:mm A");

        if (daysDifference === 0) {
          formattedDate = `Today at ${momentTZ(s.updatedAt).format("H:mm A")}`;
        } else if (daysDifference === 1) {
          formattedDate = `Yesterday at ${momentTZ(s.updatedAt).format(
            "H:mm A"
          )}`;
        }

        return (
          <div
            className={styles["statements-list-item"]}
            key={s.filename}
            onMouseEnter={() => setHoveredItemId(s._id)}
            onMouseLeave={() => setHoveredItemId(null)}
          >
            <div className={styles["list-item-left"]}>
              {momentTZ(s.period).format("MMMM YYYY")}
            </div>
            <div className={styles["list-item-right"]}>
              <span>{formattedDate}</span>
              <img
                onClick={() => onDownloadClick(s)}
                src={`${process.env.PUBLIC_URL}/icons/download${
                  hoveredItemId === s._id ? "-gr" : ""
                }.svg`}
                alt="download"
              />
            </div>
          </div>
        );
      });

      return (
        <StatementsStateController items={statements}>
          {statementsToRender}
        </StatementsStateController>
      );
    } else if (statements !== null) {
      return (
        <div className={styles["no-statements-wrapper"]}>
          <img
            src={`${process.env.PUBLIC_URL}/icons/no-statements.svg`}
            alt="statement-icon"
          />
          <span className={styles["no-statements-message"]}>
            No monthly statements available
          </span>
        </div>
      );
    } else if (loading) {
      return <Spinner className="spinner" animation="grow" />;
    } else return null;
  };

  return (
    <div className={styles["statements-wrapper"]}>
      <h2>Monthly Statements</h2>
      <div className={styles["statements-list-block"]}>
        <div className={styles["statements-list-wrapper"]}>
          {renderedStatements()}
        </div>
      </div>
    </div>
  );
};

export default MonthlyStatements;
