export const attachmentHelpers = {
  processCSV: (str, delim = ";") => {
    let headers = str.slice(0, str.indexOf("\n")).split(delim);
    let rows = str.split("\n").filter((n) => n);
    const polishedRows = rows.map((i) =>
      i
        .split("")
        .filter((i) => i !== "\r")
        .join("")
    );
    const newArray = polishedRows.map((row) => {
      const values = row.split(delim);
      const eachObject = headers.reduce((obj, header, i) => {
        obj[header] = values[i];
        return obj;
      }, {});
      return eachObject;
    });
    return newArray;
  },
};
