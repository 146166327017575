import momentTZ from "moment-timezone";

export const helperFunctions = {
  checkPermission: (permissionToCheck, profileData) =>
    profileData?.permissions && profileData?.permissions?.[permissionToCheck],
  currencyFormatter: () => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
  },
  transformToStepDateView: (date, noHours) => {
    if (noHours)
      return `${momentTZ(date).tz("America/New_York").format("MMMM D, YYYY")}`;
    return `${momentTZ(date)
      .tz("America/New_York")
      .format("MMMM D, YYYY hh:mm A z")}`;
  },
  userTime: (date = new Date(), format = "YYYY-MM-DD h:mm A", timeZone) => {
    if (timeZone) {
      return momentTZ(date).tz(timeZone).format(format);
    } else {
      return momentTZ.utc(date).format(format);
    }
  },
  roundToNearest: (number, base) => {
    if (number % base >= base / 2) {
      return Math.ceil(number / base) * base;
    } else {
      return Math.floor(number / base) * base;
    }
  },
  isRegularCategory: (category) => {
    return category.name !== "max_loss" && category.name !== "re_enable";
  },
  validateRiskSettingValue: (value, activeRiskSetting) => {
    const number = Number(value);
    if (isNaN(number) || number <= 0) {
      return "Only positive integer numbers are allowed";
    }
    const base = helperFunctions.isRegularCategory(activeRiskSetting) ? 100 : 10;
    const roundedNumber = helperFunctions.roundToNearest(number, base);
    // Ensure the number is at least the base value
    return Math.max(roundedNumber, base);
  },
};
