import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";

import { authThunks } from "action-thunks/auth-thunks";

import styles from "./styles.module.scss";

const Header = () => {
  const dispatch = useDispatch();

  const { isAuthenticated } = useSelector((state) => state.auth);

  const onLogout = () => {
    dispatch(authThunks.logoutTC());
  };

  const handleLogoClick = () => {
    window.location.assign("https://zimtra.ky/");
  };

  return (
    <div className={styles.headerBlock}>
      <img
        src={`${process.env.PUBLIC_URL}/images/logo.png`}
        alt="zimtra-logo"
        onClick={handleLogoClick}
      />
      {isAuthenticated && (
        <button className={cn("main-button", styles.logout)} onClick={onLogout}>
          Logout
        </button>
      )}
    </div>
  );
};

export default Header;
