import { constants } from "../../utilities/constants";

const {
  paths: {
    TO_GROUP_OVERVIEW,
    TO_TRADERS,
    TO_ONBOARDING,
    TO_PERSONAL_INFO,
    TO_MONTHLY_STATEMENTS,
    TO_RESOURCES,
    TO_FAQ,
    TO_MARKET_DATA,
  },
} = constants;

export const navigationList = [
  {
    id: "groupoverview",
    destination: TO_GROUP_OVERVIEW,
    summary: "Group Overview",
    icon: `${process.env.PUBLIC_URL}/icons/overview.svg`,
    "icon-active": `${process.env.PUBLIC_URL}/icons/overview-active.svg`,
    permission: "account",
  },
  {
    id: "traders",
    destination: TO_TRADERS,
    summary: "Traders",
    icon: `${process.env.PUBLIC_URL}/icons/traders.svg`,
    "icon-active": `${process.env.PUBLIC_URL}/icons/traders-active.svg`,
    permission: "account",
  },
  {
    id: "onboarding",
    destination: TO_ONBOARDING,
    summary: "Onboarding",
    icon: `${process.env.PUBLIC_URL}/icons/onboarding.svg`,
    "icon-active": `${process.env.PUBLIC_URL}/icons/onboarding-active.svg`,
    permission: "account",
  },
  {
    id: "marketdata",
    destination: TO_MARKET_DATA,
    summary: "Market Data",
    icon: `${process.env.PUBLIC_URL}/icons/marketdata.svg`,
    "icon-active": `${process.env.PUBLIC_URL}/icons/marketdata-active.svg`,
    permission: "account",
  },
  {
    id: "profile",
    destination: TO_PERSONAL_INFO,
    summary: "Personal Information",
    icon: `${process.env.PUBLIC_URL}/icons/personal.svg`,
    "icon-active": `${process.env.PUBLIC_URL}/icons/personal-active.svg`,
    permission: "account",
  },
  {
    id: "monthlystatements",
    destination: TO_MONTHLY_STATEMENTS,
    summary: "Monthly Statements",
    icon: `${process.env.PUBLIC_URL}/icons/monthly.svg`,
    "icon-active": `${process.env.PUBLIC_URL}/icons/monthly-active.svg`,
    permission: "account",
  },
  {
    id: "etb",
    destination: TO_RESOURCES,
    summary: "ETB Lists",
    icon: `${process.env.PUBLIC_URL}/icons/resources.svg`,
    "icon-active": `${process.env.PUBLIC_URL}/icons/resources-active.svg`,
    permission: "account",
  },
  // {
  //   id: "support",
  //   destination: TO_SUPPORT,
  //   summary: "Support",
  //   icon: `${process.env.PUBLIC_URL}/icons/support.svg`,
  //   "icon-active": `${process.env.PUBLIC_URL}/icons/support-active.svg`,
  //   permission: "account",
  // },
  {
    id: "faq",
    destination: `${TO_FAQ}/617a7ac30b22ab563defcb5a`,
    summary: "FAQ",
    icon: `${process.env.PUBLIC_URL}/icons/faq.svg`,
    "icon-active": `${process.env.PUBLIC_URL}/icons/faq-active.svg`,
    permission: "account",
  },
];
