import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";

import TableComponent from "components/ui-kit/react-table/TableComponent";
import { supportTableUtils } from "components/ui-kit/react-table/supportTableUtils";
import onboardingHelpers from "./helpers";
import { tradeGroupThunks } from "action-thunks/tradeGroup-thunks";
import { ModalContext } from "components/ui-kit/modal-context/ModalContext";
import { separateConstants } from "utilities/constants";
import OnboardingCredsModal from "./modals/OnboardingCredsModal";
import OnboardingTradingPackageModal from "./modals/OnboardingTradingPackageModal";
import OnboardingEffectiveDateModal from "./modals/OnboardingEffectiveDateModal";
import OnboardingExchangeAgreementModal from "./modals/OnboardingExchangeAgreementModal";

import styles from "./styles.module.scss";
import "./onboarding-global.scss";

const { tableFormattedData } = onboardingHelpers;
const { onboardingModalTypes } = separateConstants;

const Onboarding = () => {
  const { agencyList, TGAccountsList } = useSelector((state) => state.tradeGroup);

  const [copied, setCopied] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadingList, setIsLoadingisLoadingList] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const { showModal, hideModal } = useContext(ModalContext);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchRequestsPeriodically = () => {
      if (!isModalOpen) {
        if (isInitialLoad) setIsLoadingisLoadingList(true);
        dispatch(
          tradeGroupThunks.getTGAccountsListTC([
            "openMDrequest",
            "marketData",
            "firstEnableRequest",
            "riskSettings",
          ])
        ).finally(() => {
          setIsInitialLoad(false);
          setIsLoadingisLoadingList(false);
        });
      }
    };

    fetchRequestsPeriodically()

    const intervalId = setInterval(fetchRequestsPeriodically, 3000);

    return () => clearInterval(intervalId);
  }, [isModalOpen, dispatch, isInitialLoad]);

  useEffect(() => {
    if (agencyList === null) {
      dispatch(tradeGroupThunks.getAgencyListTC());
    }
  }, [agencyList, dispatch]);

  const starColumsHandler = (row, status) => {};

  const handleCloseModal = () => {
    setIsModalOpen(false);
    hideModal();
  };

  const copy = (e) => {
    e.preventDefault();
    navigator.clipboard
      .writeText(agencyList[0]?.settings?.onboardingUrl)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 3000);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
        setCopied(false); // Reset copied in case of an error as well
      });
  };

  const renderAddNewTraderButton = () => {
    return (
      <button disabled={copied} onClick={copy} className="main-button">
        <>
          {!copied && (
            <img src={`${process.env.PUBLIC_URL}/icons/copy.svg`} alt="copy" />
          )}{" "}
          {copied ? "Copied" : "Copy Onboarding Link"}
        </>
      </button>
    );
  };

  const onRowTextClickHandler = (item) => {
    setIsModalOpen((prev) => !prev);
    switch (item.modalType) {
      case onboardingModalTypes.CREDS:
        showModal(
          <OnboardingCredsModal item={item} closeModal={handleCloseModal} />
        );
        break;
      case onboardingModalTypes.EFFECTIVE_DATE:
        showModal(
          <OnboardingEffectiveDateModal
            item={item}
            closeModal={handleCloseModal}
          />
        );
        break;
      case onboardingModalTypes.EXCHANGE_AGREEMENTS:
        showModal(
          <OnboardingExchangeAgreementModal
            item={item}
            closeModal={handleCloseModal}
          />
        );
        break;
      case onboardingModalTypes.TRADING_PACKAGE:
        showModal(
          <OnboardingTradingPackageModal
            item={item}
            closeModal={handleCloseModal}
          />
        );
        break;

      default:
        break;
    }
  };

  return (
    <div className={cn("page-wrapper", styles.onboarding)}>
      <div className={styles.header}>
        <h3>Onboarding new members</h3>
      </div>
      {!isLoadingList && TGAccountsList && (
        <div className={styles.body}>
          {renderAddNewTraderButton()}
          <div className={styles["table-body"]}>
            <TableComponent
              data={tableFormattedData(TGAccountsList, onRowTextClickHandler)}
              dateColumns={["created", "updated"]}
              starColumsHandler={starColumsHandler}
              formattingForDateColumns={supportTableUtils.tableTimeFormat}
              showSearch={false}
              // isLoading={isLoading}
              //   setCellClassName={setCellClassName}
              itemsToUseSorting={[
                "fullName",
                "email",
                "DateCreated",
                "CurrentState",
              ]}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Onboarding;
