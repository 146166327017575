export const types = {
  auth: {
    SET_AUTH: "AUTH_REDUCER/SET_AUTH",
    SET_CURRENT_USER: "AUTH_REDUCER/SET_CURRENT_USER",
  },
  utils: {
    SET_IS_LOADING: "UTILS_REDUCER/SET_IS_LOADING",
    SET_NOTIFICATION_CHILDREN: "UTILS_REDUCER/SET_NOTIFICATION_CHILDREN",
    GET_ERRORS: "UTILS_REDUCER/GET_ERRORS",
    REMOVE_NOTIFICATION: "UTILS_REDUCER/REMOVE_NOTIFICATION",
  },
  profile: {
    SET_PROFILE: "PROFILE_REDUCER/SET_PROFILE",
  },
  tg: {
    SET_TGs_LIST: "TRADEGROUP_REDUCER/SET_TGs_LIST",
    SET_AGENCY_LIST: "TRADEGROUP_REDUCER/SET_AGENCY_LIST",
    SET_TG_ACCOUNTS_LIST: "TRADEGROUP_REDUCER/SET_TG_ACCOUNTS_LIST",
    SET_TG_BALANCE: "TRADEGROUP_REDUCER/SET_TG_BALANCE",
    SET_TG_REQUESTS_LIST: "TRADEGROUP_REDUCER/SET_TG_REQUESTS_LIST",
    SET_RS_METADATA: "TRADEGROUP_REDUCER/SET_RS_METADATA",
    ADD_TG_REQUEST: "TRADEGROUP_REDUCER/ADD_TG_REQUEST",
    UPDATE_REQUEST_LIST: "TRADEGROUP_REDUCER/UPDATE_REQUEST_LIST",
  },
  docs: {
    REMOVE_DOCUMENT_FROM_STORE: "DOCUMENT_REDUCER/REMOVE_DOCUMENT_FROM_STORE",
    SET_CURRENT_DOCUMENT: "DOCUMENT_REDUCER/SET_CURRENT_DOCUMENT",
    SET_LIST_OF_DOCUMENTS: "DOCUMENT_REDUCER/SET_LIST_OF_DOCUMENTS",
    SET_ETB_DOCUMENT: "DOCUMENT_REDUCER/SET_ETB_DOCUMENT",
    SET_FRESHEST_ETB_DOCUMENT: "DOCUMENT_REDUCER/SET_FRESHEST_ETB_DOCUMENT",
  },
  marketdata: {
    SET_MARKETDATA_PROVIDERS: "MARKETDATA_REDUCER/SET_MARKETDATA_PROVIDERS",
    SET_MARKET_BUNDLES: "MARKETDATA_REDUCER/SET_MARKET_BUNDLES",
    SET_TRADING_PLATFORMS: "MARKETDATA_REDUCER/SET_TRADING_PLATFORMS",
  },
  statements: {
    SET_STATEMENTS: "STATEMENTS_REDUCER/SET_STATEMENTS",
  }
};

export const errorTypes = {
  auth: {
    LOGIN_ERROR: "AUTH_REDUCER/LOGIN_ERROR",
  },
  tg: {
    SET_TG_ACCOUNTS_LIST_ERROR: "TRADEGROUP_REDUCER/SET_TG_ACCOUNTS_LIST_ERROR",
    SET_TG_BALANCE_ERROR: "TRADEGROUP_REDUCER/SET_TG_BALANCE_ERROR",
    SET_TG_REQUESTS_LIST_ERROR: "TRADEGROUP_REDUCER/SET_TG_REQUESTS_LIST_ERROR",
    GET_RIST_SETTINGS_METADATA_ERROR: "TRADEGROUP_REDUCER/GET_RIST_SETTINGS_METADATA_ERROR",
    CREATE_DATA_REQUEST_ERROR: "TRADEGROUP_REDUCER/CREATE_DATA_REQUEST_ERROR",
    UPDATE_REQUEST_STATUS_ERROR: "TRADEGROUP_REDUCER/UPDATE_REQUEST_STATUS_ERROR",
  },
  docs: {
    CURRENT_DOCUMENT_ERROR: "DOCUMENT_REDUCER/CURRENT_DOCUMENT_ERROR",
    DELETE_DOCUMENT_ERROR: "DOCUMENT_REDUCER/DELETE_DOCUMENT_ERROR",
    DOCUMENT_LIST_ERROR: "DOCUMENT_REDUCER/DOCUMENT_LIST_ERROR",
    GET_DOC_ATTACHMENT_ERROR: "DOCUMENT_REDUCER/GET_DOC_ATTACHMENT_ERROR",
    GET_AND_DOWNLOAD_FILE_ERROR: "DOCUMENT_REDUCER/GET_AND_DOWNLOAD_FILE_ERROR",
  },
  marketdata: {
    GET_MARKETDATA_PROVIDERS_ERROR: "MARKETDATA_REDUCER/GET_MARKETDATA_PROVIDERS_ERROR",
    GET_TRADING_PLATFORMS_ERROR: "MARKETDATA_REDUCER/GET_TRADING_PLATFORMS_ERROR",
  },
  statements: {
    GET_STATEMENTS_LIST_ERROR: "STATEMENTS_REDUCER/GET_STATEMENTS_LIST_ERROR",
    GET_STATEMENTS_FILE_ERROR: "STATEMENTS_REDUCER/GET_STATEMENTS_FILE_ERROR",
  }
};
