import { combineReducers } from 'redux';
import authReducer from './authReducer';
import utilsReducer from './utilsReducer';
import profileReducer from './profileReducer';
import tradeGroupReducer from './tradeGroupReducer';
import documentsReducer from './documentReducer';
import marketdataReducer from './marketdataReducer';
import statementsReducer from './statementsReducer';

const rootReducer = combineReducers({
    auth: authReducer,
    utils: utilsReducer,
    profile: profileReducer,
    tradeGroup: tradeGroupReducer,
    documents: documentsReducer,
    marketdata: marketdataReducer,
    statements: statementsReducer,
  });
  
  export default rootReducer;