import cn from "classnames";
import { setBundleColor } from "../helpers";

const Bundles = ({
  bundle,
  onboardingClient,
  isBundlesDisabled,
  buttonItem,
  changingStatus,
  primaryBundle,
  filterMarketsToTradingPlatform,
  requestedMarketsData = { status: "COMPLETED" },
  handleRequestMarketBundleChange,
}) => {
  const onBundleButtonClick = (e, name) => {
    e.preventDefault();
    if (name === primaryBundle) return;

    handleRequestMarketBundleChange({
      name,
    });
  };

  const isButtonDisabled = (
    buttonName,
    isBundlesDisabled,
    filterMarketsToTradingPlatform
  ) => {
    if (isBundlesDisabled) {
      return true;
    }
    if (buttonName === "Basic") return false;
    if (buttonName === "Custom") return true;

    const fileteredMarkets = filterMarketsToTradingPlatform?.filter(
      (m) => !!m.bundle.length
    );
    const shouldDisable = fileteredMarkets?.every((m) => m.bundle.length === 3);

    return shouldDisable;
  };

  return (
    <div
      className={cn("marketdata-bundle", {
        "onboarding-marketdata-bundle": !!onboardingClient,
      })}
    >
      <span className="bundle-text">Bundle:</span>
      <div
        className={cn("marketdata-button-group", {
          disabled: isBundlesDisabled,
        })}
      >
        {bundle.map((button) => (
          <button
            key={`radio-${button.name}`}
            disabled={isButtonDisabled(
              button.name,
              isBundlesDisabled,
              filterMarketsToTradingPlatform
            )}
            className={cn(
              "main-button",
              setBundleColor({
                name: button.name,
                changingStatus,
                buttonItem,
                primaryBundle,
                requestedMarketsData,
              })
            )}
            onClick={(e) => onBundleButtonClick(e, button.name)}
          >
            {button.name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Bundles;
