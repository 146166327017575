import { types } from "./types";

const { profile } = types;

const initialState = {
  profileData: null,
};

const profileReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case profile.SET_PROFILE:
      return {
        ...state, profileData: payload,
      };
    default:
      return state;
  }
};

export default profileReducer;
