import cn from "classnames";

import { navigationList } from "./helpers";
import { PRIVACY_POLICY } from "../../utilities/links";

import styles from "./styles.module.scss";
import { NavLink } from "react-router-dom";
import { constants } from "utilities/constants";

const SidebarNavigation = ({
  // burgerMenuOpened = false,
  activeSidebarItem,
  onSideItemClick,
  profileData,
}) => {
  const renderSideElements = navigationList
    .filter((item) => {
      if (
        item.id === "monthlystatements" &&
        !profileData?.permissions?.find(
          (p) => p.name === constants.sidebarElementsPermissionType.STATEMENTS
        )
      ) {
        return false;
      }

      return true;
    })
    .map((item) => {
      // if (helperFunctions.checkPermission(item.permission, profileData))
      const isActive = activeSidebarItem?.summary === item.summary;
      return (
        <NavLink
          to={item.destination}
          onClick={() => onSideItemClick(item)}
          className={cn(styles["navlink-wrapper"], {
            [styles["active-link"]]: isActive,
          })}
          key={item.id}
        >
          <img
            width="35"
            height="30"
            src={isActive ? item["icon-active"] : item.icon}
            alt={item.summary}
          />
          {item.summary}
        </NavLink>
      );
    });

  return (
    <>
      {/* {burgerMenuOpened && <div className={styles["blacked-bg"]} />} */}
      <div
        className={
          styles["side-block"]
          // burgerMenuOpened ? "opened-menu" : "closed-menu"
        }
      >
        <div className={styles["fixed-side-elements"]}>
          <div className={styles["upper-side"]}>{renderSideElements}</div>
        </div>
        <div className={styles["copyright"]}>
          <a href={PRIVACY_POLICY} target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
          <span>{`© ${new Date().getFullYear()} ZIMTRA, All Rights Reserved`}</span>
        </div>
      </div>
    </>
  );
};

export default SidebarNavigation;
