import { useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";

// import ChangePasswordBlock from "ui/change-password/ChangePassword";

import styles from "./styles.module.scss";
import "./profile-global.scss";

const Profile = () => {
  const { profileData } = useSelector((state) => state.profile);

  const [localState, setLocalState] = useState(profileData);

  useEffect(() => {
    if (profileData) {
      setLocalState(profileData);
    }
  }, [profileData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      if (value.match(/^[0-9\\+\\-]+(\s+[0-9]+)*$/)) {
        setLocalState({ ...localState, [name]: value });
      } else if (value.length === 0) {
        setLocalState({ ...localState, [name]: value });
      } else return;
    }
    if (name === "firstname" || name === "lastname") {
      if (value.match(/^[a-zA-Z\\-]+$/)) {
        setLocalState({ ...localState, [name]: value });
      } else if (value.length === 0) {
        setLocalState({ ...localState, [name]: value });
      } else return;
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (localState.firstname.length > 40 || localState.lastname.length > 80) {
      // return dispatch(
      //   setToastStateAC({
      //     show: true,
      //     message: "Too many symbols in firstname/lastname",
      //     type: ADM_ERROR_POPUP,
      //   })
      // );
    }
    // dispatch(profileThunks.updateProfileTC(localState));
  };

  return (
    <div className={styles["edit-profile-page-block"]}>
      <Card className="edit-profile-card">
        <div className="form-block">
          <Form onSubmit={submitHandler} className="form-wrapper">
            <h3>Personal Information</h3>
            <Form.Group
              className="form-group-block mb-3"
              controlId="profile-name"
            >
              <Form.Label>First name</Form.Label>
              <Form.Control
                type="text"
                name="firstname"
                readOnly
                value={localState?.firstname}
                onChange={handleChange}
                placeholder="Your name"
              />
            </Form.Group>

            <Form.Group
              className="form-group-block mb-3"
              controlId="profile-lastname"
            >
              <Form.Label>Last name</Form.Label>
              <Form.Control
                type="text"
                name="lastname"
                readOnly
                value={localState?.lastname}
                onChange={handleChange}
                placeholder="Your lastname"
              />
            </Form.Group>

            <Form.Group
              className="form-group-block mb-3"
              controlId="profile-phone"
            >
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="username"
                readOnly
                value={localState?.username}
                placeholder="Your email"
              />
            </Form.Group>
          </Form>

          {/* {changePasswordBlock ? (
            <ChangePasswordBlock
              setChangePasswordBlock={setChangePasswordBlock}
            />
          ) : (
            <Button
              size="sm"
              buttonStyle="zimtra-secondary"
              buttonClassName="edit-profile-main-button"
              onClick={() => setChangePasswordBlock(!changePasswordBlock)}
              label="Change password"
            />
          )} */}
        </div>
      </Card>
    </div>
  );
};

export default Profile;
