import { useEffect } from "react";
import { Routes, Route, useLocation, useNavigate, Navigate } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import { routesList } from "./routesList";
import { constants } from "../utilities/constants";
import PageLayoutWrapper from "../components/layout";
import { useDispatch, useSelector } from "react-redux";
import { AuthAPI } from "api/auth-api";
import { setIsAuthenticatedAC } from "action-thunks/actions";
import LoginPage from "components/pages/login";

const AppRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);

  const isAuthenticatedUser = (AuthAPI.getToken() || AuthAPI.getSessionToken()) || isAuthenticated;

  useEffect(() => {
    if ((AuthAPI.getToken() || AuthAPI.getSessionToken()) && !isAuthenticated)
      dispatch(setIsAuthenticatedAC(true));
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    if (location.pathname === "/") {
      navigate(constants.paths.TO_LOGIN);
    }
  }, []);

  return (
    <Routes>
      <Route
        path={constants.paths.TO_LOGIN}
        element={<LoginPage isAuthenticated={isAuthenticated} />}
      />
      <Route element={<PrivateRoute isAuthenticatedUser={isAuthenticatedUser} />}>
        {routesList.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              element={<PageLayoutWrapper component={route.component} />}
            />
          );
        })}
      </Route>
      <Route path="*" element={<Navigate to={constants.paths.TO_LOGIN} replace />} />
    </Routes>
  );
};

export default AppRoutes;
