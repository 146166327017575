import moment from "moment/moment";
import momentTZ from "moment-timezone";
import { separateConstants } from "utilities/constants";
import { helperFunctions } from "utilities/helper-functions";

const { RequestFormStatusTypes, notActiveRequestsStates } = separateConstants;

const getTPRefByName = (tp, tpt, tradingPlatforms) => {
  if (tradingPlatforms)
    return tradingPlatforms.find(
      (t) => t.software === tp && t.softwareType === tpt
    )?.reference;
  return "";
};

const getBundleName = ({
  markets,
  marketdataProviders,
  marketBundles,
  tradingPlatformRef,
}) => {
  // Filter market data entitlements based on the trading platform reference
  const relevantEntitlements = marketdataProviders?.filter((entitlement) =>
    entitlement.tradingPlatformRefs.includes(tradingPlatformRef)
  );

  // Further filter the entitlements to those whose names appear in the markets list
  const matchedEntitlements = relevantEntitlements?.filter((entitlement) =>
    markets?.includes(entitlement.name)
  );

  // Determine the bundle name based on the matched entitlements
  if (marketBundles) {
    for (const bundle of marketBundles) {
      if (matchedEntitlements?.length > 0) {
        const isAllMatchedEntitlementsInBundle = matchedEntitlements.every(
          (entitlement) => entitlement.bundle.includes(bundle.name)
        );

        const totalProvidersInBundle = relevantEntitlements.filter(
          (entitlement) => entitlement.bundle.includes(bundle.name)
        ).length;

        if (
          isAllMatchedEntitlementsInBundle &&
          totalProvidersInBundle === matchedEntitlements.length
        ) {
          return bundle.name; // All matched entitlements are in this bundle
        }
      }
    }
  }

  // If no bundle matches all conditions, return "Custom"
  return "Custom";
};

const getEffectiveDate = (requestedMarketsData) => {
  if (notActiveRequestsStates.includes(requestedMarketsData?.status)) {
    return moment().add(1, "M").startOf("month").format("YYYY-MM-DD");
  }
  return moment(requestedMarketsData?.schedule.duedate).format("YYYY-MM-DD");
};

const setToPrimaryDataHandler = ({
  requestedMarketsData,
  setLocalState,
  localState,
  setPrimaryEntitlements,
  extensions,
  getMarkets,
}) => {
  const getMarketsFromExtHandler = (type) => {
    return getMarketsFromExt(type, requestedMarketsData, extensions);
  };
  const primeMarkets = getMarketsFromExtHandler("ext");
  let markets = getMarketsFromExtHandler("ext");
  const addMarkets = [];

  const removeMarkets = getMarketsFromExtHandler("remove");
  // set add markets
  if (requestedMarketsData.status !== RequestFormStatusTypes.COMPLETED) {
    getMarketsFromExtHandler("add")?.forEach((m) => {
      if (!getMarketsFromExtHandler("ext").includes(m)) {
        addMarkets.push(m);
      }
    });
  }
  // set localState markets
  if (!!removeMarkets?.length) {
    markets = markets.filter((m) => !removeMarkets.includes(m));
  }
  if (!!addMarkets?.length) {
    addMarkets.forEach((m) => !markets.includes(m) && markets.push(m));
  }

  if (getMarkets) {
    return markets;
  }

  setLocalState({
    ...localState,
    markets: markets,
    addMarkets,
    effectiveDate: getEffectiveDate(requestedMarketsData),
    removeMarkets: removeMarkets,
  });
  setPrimaryEntitlements(primeMarkets);
};

const getMarketsFromExt = (type, requestedMarketsData, extensions) => {
  if (type === "remove")
    return requestedMarketsData.ext?.removeMarkets?.map((m) => m.market);
  if (type === "add")
    return requestedMarketsData.ext?.addMarkets?.map((m) => m.market);
  if (type === "ext") {
    if (!!extensions?.length) {
      return extensions
        .find((e) => e.code === "trading/marketdata")
        ?.ext?.markets.map((m) => m.market);
    } else {
      return [];
    }
  }
  return requestedMarketsData.ext?.addMarkets?.map((m) => m.market);
};

const onMarketStatusChangeHandler = ({
  name,
  value,
  reference,
  depRef,
  localState,
  copyLocalState,
  setLocalState,
  filterMarketsToTradingPlatform,
  primaryEntitlements,
  setDataWasChanged,
  requestedMarketsData = {status: "COMPLETED"},
  // setCurrentPopupAction,
  // showModal,
  // hideModal,
  setChosenButton,
}) => {
  let newMarkets =
    localState && localState.markets ? [...localState.markets] : [];
  const newRemoveMarkets = [];
  const newAddMarkets = [];
  if (value) {
    if (depRef.length) {
      depRef.forEach((ref) => {
        if (
          !newMarkets.includes(
            filterMarketsToTradingPlatform.find((m) => m.reference === ref)
              ?.name
          )
        ) {
          newMarkets.push(
            filterMarketsToTradingPlatform.find((m) => m.reference === ref).name
          );
        }
      });
    }
    newMarkets.push(name);
  } else {
    if (reference) {
      const marketsToFilter = new Set(
        filterMarketsToTradingPlatform
          .filter((el) => el.dependencyRefs.includes(reference))
          .map((el) => el.name)
      );
      newMarkets = newMarkets.filter((m) => !marketsToFilter.has(m));
    }

    newMarkets = newMarkets.filter((m) => m !== name);
  }
  // compare markets chosen and primary
  const array2Sorted = newMarkets.slice().sort();
  if (
    primaryEntitlements.length === newMarkets.length &&
    primaryEntitlements
      .slice()
      .sort()
      .every((value, index) => value === array2Sorted[index]) &&
    !!newMarkets.length
  ) {
    if (notActiveRequestsStates.includes(requestedMarketsData.status)) {
      setDataWasChanged(() => false);
    }
  } else {
    setDataWasChanged(() => true);
  }

  // set addMarkets and removeMarkets
  filterMarketsToTradingPlatform.forEach((m) => {
    if (primaryEntitlements.includes(m.name) && !newMarkets?.includes(m.name))
      newRemoveMarkets.push(m.name);
    if (!primaryEntitlements?.includes(m.name) && newMarkets?.includes(m.name))
      newAddMarkets.push(m.name);
  });
  // detect no markets chosen
  if (newMarkets.length === 0) {
    setLocalState({
      ...localState,
      markets: [],
    });
    if (copyLocalState) {
      copyLocalState([]);
    }
    // showModal(
    //   <ConfirmationModal
    //     isApplyButtonRed
    //     showEditModal
    //     closeModal={hideModal}
    //     onApplyClick={() => setCurrentPopupAction("no-markets")}
    //     labels={{
    //       title: "Remove all Market Data subscriptions?",
    //       body: "Do you really want to unsubscribe from all Market Data Entitlements?",
    //       buttons: { apply: "Yes", decline: "No" },
    //     }}
    //   />
    // );
  } else {
    setLocalState({
      ...localState,
      markets: newMarkets,
      addMarkets: newAddMarkets,
      removeMarkets: newRemoveMarkets,
    });
    if (copyLocalState) {
      copyLocalState(newMarkets);
    }
  }

  setChosenButton(newMarkets);
};

const getTotalPriceHandler = (markets, filterMarketsToTradingPlatform) => {
  if (markets) {
    const total = markets.reduce((acc, marketName) => {
      const market = filterMarketsToTradingPlatform?.find(
        (m) => m.name === marketName
      );
      return acc + (market?.price || 0);
    }, 0);

    return helperFunctions.currencyFormatter().format(total);
  } else return helperFunctions.currencyFormatter().format(0);
};

const setBundleColor = ({
  name,
  changingStatus,
  buttonItem,
  primaryBundle,
  requestedMarketsData,
}) => {
  if (name === buttonItem && buttonItem !== primaryBundle) {
    return "waiting-bundle";
  }
  if (
    name === buttonItem &&
    buttonItem === primaryBundle &&
    (changingStatus ||
      requestedMarketsData.status !== RequestFormStatusTypes.COMPLETED)
  ) {
    return "waiting-bundle";
  }
  if (name === primaryBundle) return "primary-bundle";
};

const setColorStatus = ({ name, primaryEntitlements, entitlements }) => {
  if (!primaryEntitlements?.includes(name) && entitlements?.includes(name))
    return "added-item market-checkmark-done";
  if (primaryEntitlements?.includes(name) && !entitlements?.includes(name))
    return "removed-item market-checkmark";
  return "";
};

const getRequiredRefs = (arrayOfRefsToFind, marketdataProviders) => {
  return arrayOfRefsToFind
    ?.map((r) => marketdataProviders.find((m) => m.reference === r)?.name)
    .filter(Boolean); // Filter out any undefined values
};

const getMarketWithName = ({ filterMarketsToTradingPlatform, name }) => {
  return filterMarketsToTradingPlatform
    ?.filter((m) => m.bundle.includes(name))
    .map((m) => m.name);
};

const getAccountStatusText = (defaultTraderIds, TGAccountsList) => {
  const found = TGAccountsList?.find(
    (t) => t.reference?.client === defaultTraderIds
  );
  if (found && found.status === "AccountEnabled") {
    return <span className="greyed-text">(enabled)</span>;
  }
  return <span className="account-disabled-status">(disabled)</span>;
};

const getMarketsToSend = ({ markets, filterMarketsToTradingPlatform }) =>
  filterMarketsToTradingPlatform
    .filter((f) => markets.includes(f.name))
    .map((f) => ({ group: f.group, market: f.name }));

const checkTwoMDArraysToIdentity = (array1 = [], array2 = []) => {
  const newMarkets = array1;
  const array2Sorted = newMarkets?.slice().sort();
  return (
    array2?.length === newMarkets?.length &&
    array2
      .slice()
      .sort()
      .every((value, index) => value === array2Sorted[index])
  );
};

const checkIfNotValidMarketdataRequest = (
  localValue,
  newMarkets,
  primaryMarkets
) => {
  if (checkTwoMDArraysToIdentity(newMarkets, primaryMarkets)) {
    return true;
  }

  return (
    localValue === "Invalid date" ||
    momentTZ(localValue).isBefore(new Date(), "day") ||
    momentTZ(localValue).isAfter(momentTZ().add(1, "year"))
  );
};

export {
  getTPRefByName,
  getBundleName,
  setToPrimaryDataHandler,
  getMarketsFromExt,
  getAccountStatusText,
  onMarketStatusChangeHandler,
  getTotalPriceHandler,
  setBundleColor,
  getRequiredRefs,
  getMarketWithName,
  setColorStatus,
  getMarketsToSend,
  checkTwoMDArraysToIdentity,
  checkIfNotValidMarketdataRequest,
};
