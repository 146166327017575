import { useState } from "react";
import styles from "./styles.module.scss";

const CopyToClipboard = ({ text }) => {
  const [copied, setCopied] = useState(false);

  const copy = (e) => {
    e.preventDefault();
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopied(true);
        // Set a timer to reset 'copied' after 3 seconds
        setTimeout(() => setCopied(false), 3000);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
        setCopied(false); // Reset copied in case of an error as well
      });
  };

  return (
    <div className={styles.listItem}>
      <span onClick={copy}>
        {text}{" "}
        <img
          src={`${process.env.PUBLIC_URL}/icons/${
            copied ? "check" : "copy"
          }.svg`}
          alt="copy"
        />
      </span>
    </div>
  );
};

export default CopyToClipboard;
