import { AuthAPI } from "../api/auth-api";
import { setAuthToken } from "../api/utils";
import {
  setCurrentUserAC,
  setErrorAC,
  setIsAuthenticatedAC,
  setLoadingAC,
} from "./actions";
import { constants } from "../utilities/constants";
import { errorTypes } from "reducers/types";

const {
  tokens: { LOG_IN_TOKEN },
} = constants;

const {
  auth: { LOGIN_ERROR },
} = errorTypes;

const eraseTokens = () => {
  localStorage.removeItem(LOG_IN_TOKEN);
  localStorage.removeItem("webSocketToken");
  localStorage.removeItem("token");
  sessionStorage.removeItem(LOG_IN_TOKEN);
};

//Auth thunks
export const authThunks = {
  loginTC: (userData) => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await AuthAPI.login(userData);
      if (response.status === 200) {
        dispatch(setCurrentUserAC(userData));
        const { token } = response.data;
        localStorage.setItem(LOG_IN_TOKEN, token);
        setAuthToken(token);
        dispatch(setIsAuthenticatedAC(true));
      }
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(setErrorAC({ type: LOGIN_ERROR, message: "Wrong email or password" }));
      }
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  logoutTC: () => async (dispatch) => {
    try {
      eraseTokens();
      setAuthToken();
      dispatch(setIsAuthenticatedAC(false));
      window.location.assign("/login");
    } catch (error) {
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
};
