import { useState, useEffect } from "react";
import cn from "classnames";

import "./styles.scss";
import { Tooltip } from "react-tooltip";

const allowedAccepts = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/gif",
  "image/bmp",
  "video/mp4",
  "video/mov",
  "video/wmv",
  "video/avi",
  "video/flv",
  ".pdf",
  ".csv",
  ".txt",
  ".xls",
  ".xlsx",
];

const allowedExtensions = [
  "PDF",
  "CSV",
  "XLS",
  "XLSX",
  "TXT",
  "JPEG",
  "JPG",
  "PNG",
  "GIF",
  "BMP",
  "MP4",
  "MOV",
  "WMV",
  "AVI",
  "FLV",
];

const Upload = ({
  shouldReset = false,
  accepts = allowedAccepts,
  handleChange,
  maxSize = 5,
  tooltip,
  maxElementsSize = 5 * 1024 * 1024,
}) => {
  const [attachments, setAttachments] = useState([]);
  const [error, setError] = useState(null);

  const handleFileChange = (event) => {
    const files = event.target.files;
    const updatedAttachments = [...attachments];
    let isValidFileSize = true;
    if (
      updatedAttachments.length <= maxSize &&
      files.length <= maxSize &&
      files.length + updatedAttachments.length <= maxSize
    ) {
      const combinedAttachments = [...updatedAttachments, ...files];
      for (let i = 0; i < combinedAttachments.length; i++) {
        if (combinedAttachments[i].size > maxElementsSize) {
          setError("Total size exceeds the limit");
          isValidFileSize = false;
        }
      }
      if (isValidFileSize) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const extension = file.name.split(".").pop().toUpperCase();
          // Check if the file type is allowed
          if (allowedExtensions.includes(extension.toUpperCase())) {
            updatedAttachments.push(file);
          }
        }

        setAttachments(updatedAttachments);
        setError(null);
        isValidFileSize = true;
      }
    }
  };

  const handleRemoveAttachment = (index) => {
    const updatedAttachments = [...attachments];
    updatedAttachments.splice(index, 1);
    setAttachments(updatedAttachments);
  };

  useEffect(() => {
    if (shouldReset) {
      setAttachments([]);
    }
  }, [shouldReset]);

  useEffect(() => {
    handleChange(attachments);
  }, [attachments, handleChange]);

  return (
    <div className="upload-container">
      <div className="upload-inner-container">
        <label className={cn({ disabled: attachments.length >= maxSize })}>
          <input
            onChange={handleFileChange}
            accept={accepts.join(", ")}
            onClick={(e) => {
              e.target.value = "";
            }}
            type="file"
            multiple
          />
          <span>Attach file</span>
        </label>
        <Tooltip placement="bottom-end" isClosable={false} {...tooltip}>
          <img src={`${process.env.PUBLIC_URL}/icons/info.svg`} alt="info" />
        </Tooltip>
      </div>
      {error && <div className="error-text">{error}</div>}
      {attachments.length > 0 && (
        <ul>
          {attachments.map((attachment, index) => (
            <li key={index} onClick={() => handleRemoveAttachment(index)}>
              {attachment.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export { Upload };
