// @ts-nocheck
import React, { useState, useEffect, isValidElement } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  useAsyncDebounce,
} from "react-table";
import cn from "classnames";

import "./global-table.scss";

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="search-block">
      <span>Search: </span>
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: "1.1rem",
          border: "0",
        }}
      />
    </div>
  );
}

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      className="select-filter-block"
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

const TableComponent = ({
  data,
  isLoading = false,
  dateColumns,
  formattingForDateColumns,
  showSearch,
  checkboxColumsHandler,
  showRowOverlay,
  checkedItems,
  disabledCheckbox,
  itemsWithSelectFilter = "",
  itemsToUseSorting, //must be an array
  setCellClassName = () => {},
}) => {
  // const [starredItems, setStarredItems] = useState([]);

  // const handleStarChange = (rowId) => {
  //   setStarredItems((prev) => {
  //     const currentIndex = prev.indexOf(rowId);
  //     if (currentIndex === -1) {
  //       // Not currently starred, add it
  //       return [...prev, rowId];
  //     } else {
  //       // Currently starred, remove it
  //       return prev.filter((id) => id !== rowId);
  //     }
  //   });
  // };

  //column structure
  const columns = React.useMemo(() => {
    const handleCheckboxChange = (row) => {
      checkboxColumsHandler(row);
    };

    const CheckboxCell = ({ row }) => {
      return (
        <label className="main-checkbox">
          <input
            checked={checkedItems?.some((i) => i.original?.traderId === row.original?.traderId)}
            onChange={() => handleCheckboxChange(row)}
            type="checkbox"
            disabled={isValidElement(row.original.RequestedMarketData)}// TODO: change to something not defined (now works only for MD RequestedMarketData)
          />
          <span className="checkmark"></span>
        </label>
      );
    };

    // const StarCell = ({ row }) => {
    //   return (
    //     <div className="star-column-item" onClick={() => handleStarChange(row.id)}>
    //       {starredItems.includes(row.id) ? "★" : "☆"}{" "}
    //       {/* This can be replaced with images or icons */}
    //     </div>
    //   );
    // };

    const checkboxColumn = {
      Header: "",
      accessor: "checked",
      Cell: CheckboxCell,
    };

    // const starColumn = {
    //   Header: "",
    //   accessor: "starred",
    //   Cell: StarCell,
    // };

    const otherColumns = data.length
      ? Object.keys(data[0])
          .filter(
            (key) =>
              key !== "checked" && key !== "starred" && key !== "hiddenData"
          ) // Avoid duplicate accessors
          .map((item) => {
            let itemIsSelectFilter = false;
            let filterObject = null;
            if (Array.isArray(itemsWithSelectFilter)) {
              itemIsSelectFilter = itemsWithSelectFilter.includes(
                item.toLowerCase()
              );
            } else {
              itemIsSelectFilter = item.toLowerCase() === itemsWithSelectFilter;
            }
            if (itemIsSelectFilter) {
              filterObject = {
                Header: item,
                accessor: item,
                filter: "includes",
                Filter: SelectColumnFilter,
              };
            } else {
              let accessor = item;

              filterObject = {
                Header: item,
                accessor,
              };

              if (dateColumns) {
                filterObject.formatCell = dateColumns.includes(accessor) // format cell if column in dateColumns
                  ? formattingForDateColumns
                  : undefined;
              }

              if (dateColumns && dateColumns.includes(accessor)) {
                // you only use this sort method for columns with dates, when the dateColumns prop is passed
                filterObject.sortType = (rowA, rowB) => {
                  let dateA = new Date(rowA.values[accessor]);
                  let dateB = new Date(rowB.values[accessor]);
                  return dateA > dateB ? 1 : -1;
                };
              }
            }
            return filterObject;
          })
      : null;

    let columsToReturn = [...otherColumns];

    // if (starColumsHandler) {
    //   columsToReturn = [starColumn, ...columsToReturn];
    // }
    if (checkboxColumsHandler) {
      columsToReturn = [checkboxColumn, ...columsToReturn];
    }
    return [
      {
        Header: "none",
        columns: columsToReturn,
      },
    ];
  }, [
    data,
    itemsWithSelectFilter,
    checkedItems,
    formattingForDateColumns,
    dateColumns,
    checkboxColumsHandler,
  ]);
  // Use the state and functions returned from useTable to build your UI

  const [sortBy, setSortBy] = useState([{ id: "id", desc: true }]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
      initialState: { sortBy },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy // usesorting!
  );

  useEffect(() => {
    setSortBy(state.sortBy);
  }, [state.sortBy]);

  const renderNoDataBlock = () => {
    if (data.at(-1) === "no-data") {
      return (
        <div className="account-overview-no-data-block">
          <span>No active requests</span>
        </div>
      );
    }
  };

  const setItemsToUseSorting = (currentColumn) => {
    if (itemsToUseSorting.length > 0 && data.length > 1) {
      const arrayToCheck = [
        ...itemsToUseSorting.map((item) => item.toLowerCase()),
      ];
      if (arrayToCheck.includes(currentColumn.Header.toLowerCase())) {
        return currentColumn.getSortByToggleProps();
      }
    }
  };

  const renderLoadingBackground = () => {
    if (isLoading) {
      return <div className="table-loading-bg" />;
    }
  };

  // Render the UI for your table
  return (
    <>
      <table {...getTableProps()} className="table-wrapper">
        {showSearch && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        )}
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={i}>
              {headerGroup.headers.map((column, ind) => {
                return (
                  <th key={ind}>
                    <div
                      className="column-title"
                      {...column.getHeaderProps(
                        itemsToUseSorting
                          ? setItemsToUseSorting(column)
                          : undefined
                      )}
                    >
                      {column.isSorted && (
                        <span
                          className={cn({
                            ascending: column.isSortedDesc,
                            descending: !column.isSortedDesc,
                          })}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/icons/arrow.svg`}
                            alt="arrow"
                          />
                        </span>
                      )}
                      <span>{column.render("Header")}</span>
                      {/* Render the columns filter UI */}
                    </div>
                    {column.filter && <div>{column.render("Filter")}</div>}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps()}
          className={cn({
            hide: data.at(-1) === "no-data",
          })}
        >
          {rows?.at(-1)?.original !== "no-data" &&
            rows.map((row, i, array) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, ind) => {
                    const value = cell.column.formatCell
                      ? cell.column.formatCell(cell.value)
                      : cell.render("Cell");
                    return (
                      <td
                        key={ind}
                        className={cn({
                          [setCellClassName(cell)]: setCellClassName(cell),
                        })}
                        {...cell.getCellProps()}
                      >
                        {value}
                      </td>
                    );
                  })}
                  {showRowOverlay &&
                    showRowOverlay(row, i === array.length - 1)}
                </tr>
              );
            })}
        </tbody>
        {renderLoadingBackground()}
      </table>
      {renderNoDataBlock()}
    </>
  );
};

export default React.memo(TableComponent);
