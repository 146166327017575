import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Breadcrumb, Spinner } from "react-bootstrap";
import cn from "classnames";

import { documentThunks } from "action-thunks/document-thunks";
import { documentHelpers } from "./helpers";
import Documents from "components/documents/Documents";
import { setCurrentDocumentAC } from "action-thunks/actions";

import styles from "./styles.module.scss";
import "./global-styles.scss";

const FAQ = () => {
  const { documentId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.utils);
  const { currentDocument, documents } = useSelector(
    (state) => state.documents
  );

  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const findDocumentById = (docs, id) => {
    return docs.find((doc) => doc.id === id);
  };

  useEffect(() => {
    if (documents === null) {
      dispatch(documentThunks.getListDocumentsTC());
    }
  }, [documents, dispatch]);

  useEffect(() => {
    if (documents) {
      const breadcrumbTrail = [];
      let currentId = documentId;
      
      while (currentId) {
        // Find the document with the current ID
        const currentDoc = findDocumentById(documents, currentId);

        if (currentDoc) {
          breadcrumbTrail.unshift(currentDoc);
          currentId = currentDoc.parent;
        } else {
          break;
        }
      }
      setBreadcrumbs(breadcrumbTrail);
    }

    if (
      documentId &&
      documents &&
      documents.find((d) => d.linkname === "faq")?.id !== documentId
    ) {
      dispatch(
        documentThunks.getCurrentDocumentTC(
          findDocumentById(documents, documentId)
        )
      );
    }

    if (
      documents &&
      documents?.find((d) => d.linkname === "faq")?.id === documentId
    ) {
      dispatch(setCurrentDocumentAC(null));
    }
  }, [documentId, documents, dispatch]);

  const handleDocumentClick = (doc) => {
    navigate(`/faq/${doc.id}`);
    dispatch(documentThunks.getCurrentDocumentTC(doc));
  };

  const onCrumbClick = (crumbId) => {
    if (crumbId !== documentId) {
      dispatch(setCurrentDocumentAC(null));
      navigate(`/faq/${crumbId}`);
    }
  };

  const renderBreadcrumbItems = () => {
    return breadcrumbs.map((crumb, index) => (
      <Breadcrumb.Item
        key={crumb.id}
        onClick={() => onCrumbClick(crumb.id)}
        active={index === breadcrumbs.length - 1}
      >
        <span
          className={cn(styles.crumbTitle, {
            [styles.activeBreadcrumb]: index === breadcrumbs.length - 2,
          })}
        >
          {crumb.summary}
        </span>
        {index === breadcrumbs.length - 2 && (
          <span className={styles.slash}>/</span>
        )}
      </Breadcrumb.Item>
    ));
  };

  const renderDocumentList = (parentId = null) => {
    if (documents) {
      const filteredDocs = Object.values(documents).filter(
        (doc) => doc.permission === "PUBLIC" && doc.parent === parentId
      );

      return documentHelpers.sortNavigationBarArray(filteredDocs).map((doc) => (
        <li key={doc.id} onClick={() => handleDocumentClick(doc)}>
          {doc.summary}
        </li>
      ));
    }
  };

  return (
    <div key={documentId} className={cn("page-wrapper", styles.faq)}>
      <h2 className={styles.title}>
        {currentDocument ? currentDocument.summary : "Zimtra FAQ"}
      </h2>
      <Breadcrumb className={styles.breadcrumb}>
        {renderBreadcrumbItems()}
      </Breadcrumb>
      <div className={cn(styles.docListBlock)}>
        {(currentDocument?.summary === "Zimtra FAQ" || !currentDocument) &&
          !isLoading && (
            <span className={styles.inThisSection}>In this section</span>
          )}
        {currentDocument ? (
          <Documents
            pickedDocument={currentDocument}
            hideSummary={
              currentDocument?.summary !== "Zimtra FAQ" || currentDocument
            }
          />
        ) : (
          <div className={styles.faqListBlock}>
            <ul className={styles.docList}>{renderDocumentList(documentId)}</ul>
          </div>
        )}
        {isLoading && <Spinner className="spinner" animation="grow" />}
      </div>
    </div>
  );
};

export default FAQ;
