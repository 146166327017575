import { useDispatch } from "react-redux";
import styles from "./styles.module.scss";
import { removeNotificationAC } from "action-thunks/actions";

const Notification = ({ children, id, type = "error", title }) => {
  const dispatch = useDispatch();
  if (!children) return null;

  const onClose = () => {
    dispatch(removeNotificationAC(id));
  };

  const renderTitle = () => {
    if (type === "error") {
      return (
        <div className={styles.title}>
          <img
            onClick={onClose}
            alt="alert"
            src={`${process.env.PUBLIC_URL}/icons/alert.svg`}
          />
          <span>{title ? title : "Request failed"}</span>
          <img
            onClick={onClose}
            alt="close"
            src={`${process.env.PUBLIC_URL}/icons/close.svg`}
          />
        </div>
      );
    }
  };

  return (
    <div className={styles.notification}>
      {renderTitle()}
      {children}
    </div>
  );
};

export default Notification;
