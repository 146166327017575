import { marketdataAPI } from "api/marketdata-api";
import {
  setErrorAC,
  setLoadingAC,
  setMarketBundleAC,
  setMarketdataProvidersAC,
  setTradingPlatformAC,
} from "./actions";
import { errorTypes } from "reducers/types";

const {
  marketdata: { GET_MARKETDATA_PROVIDERS_ERROR, GET_TRADING_PLATFORMS_ERROR },
} = errorTypes;

export const marketdataThunks = {
  getMarketDataProvidersTC: () => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await marketdataAPI.getMarketdataProviders();
      if (response.status === 200) {
        dispatch(
          setMarketdataProvidersAC(response.data.marketdataproviders || [])
        );
        dispatch(setMarketBundleAC(response.data.bundle || []));
      }
    } catch (error) {
      dispatch(
        setErrorAC({
          type: GET_MARKETDATA_PROVIDERS_ERROR,
          message: error.response.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
  getTradingPlatformsTC: () => async (dispatch) => {
    try {
      dispatch(setLoadingAC(true));
      const response = await marketdataAPI.getTradingPlatforms();
      if (response.status === 200) {
        dispatch(setTradingPlatformAC(response.data.tradingplatforms));
      }
    } catch (error) {
      dispatch(
        setErrorAC({
          type: GET_TRADING_PLATFORMS_ERROR,
          message: error.response.data?.message,
        })
      );
    } finally {
      dispatch(setLoadingAC(false));
    }
  },
};
