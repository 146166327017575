import { useRef, useEffect, useState, useMemo } from "react";
import cn from "classnames";
import styles from "./styles.module.scss";

export const MenuContext = ({
  options,
  className,
  styleType = "primary",
  isOpen = false,
  side = "right",
  menuType = "dots",
  profileInitials = "No Any",
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(isOpen);
  const menuRef = useRef(null);

  const getFirstCharsUppercase = useMemo(() => {
    const [firstName, lastName] = profileInitials
      .split(" ")
      .map((w) => w.charAt(0).toUpperCase());
    return lastName ? `${firstName}${lastName}` : firstName;
  }, [profileInitials]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleButtonClick = (title, href, onClick) => {
    setIsMenuOpen(false);
    if (onClick) onClick(title, href);
  };

  const renderOptions = options.map(
    ({
      title,
      href,
      onClick,
      disabled,
      iconName,
      withDivider,
      withDoneIcon,
    }) => (
      <li key={title + href}>
        <button
          type="button"
          onClick={() => !disabled && handleButtonClick(title, href, onClick)}
          className={cn(styles["body-default"], {
            [styles.disabled]: disabled,
            [styles.withDivider]: withDivider,
            [styles.withDoneIcon]: withDoneIcon,
          })}
          disabled={disabled}
        >
          {/* {iconName && <Icon name={iconName} />} */}
          <span>{title}</span>
          {/* {withDoneIcon && <Icon name="check" />} */}
        </button>
      </li>
    )
  );

  return (
    <div
      className={cn(
        [styles[styleType]],
        {
          [styles.profileStyle]: menuType === "profile",
        },
        className
      )}
      data-testid="menu-context"
      onClick={() => setIsMenuOpen(!isMenuOpen)}
      ref={menuRef}
    >
      {menuType === "dots" ? (
        <img
          src={`${process.env.PUBLIC_URL}/icons/more-vertical.svg`}
          alt="more"
        />
      ) : (
        <div
          className={styles.initialBlock}
          role="presentation"
        >
          <span>{getFirstCharsUppercase}</span>
          <img src={`${process.env.PUBLIC_URL}/icons/chevron-down.svg`} alt="chevron"/>
        </div>
      )}

      {isMenuOpen && (
        <div
          className={cn(styles["title-menu"], {
            [styles["left-side"]]: side === "left",
            [styles["top-side"]]: side === "top",
            [styles["bottom-side"]]: side === "bottom",
          })}
        >
          {options.length > 0 && (
            <ul>
              {menuType === "profile" && (
                <li className={styles.profileOption}>
                  <div className={styles.initialBlock} role="presentation">
                    <span>{getFirstCharsUppercase}</span>
                  </div>
                  <p>{profileInitials}</p>
                </li>
              )}
              {renderOptions}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};
