import cn from "classnames";
import { useDebounce } from "../hooks/useDebounce";
import { useChildren } from "../hooks/useChildren";

import { ModalBase } from "../ModalBase/ModalBase";
import styles from "./Modal.module.scss";

export const Header = ({ title, className }) => (
  <div className={cn(styles.header, className)} data-testid="modal__header">
    {title}
  </div>
);

Header.displayName = "ModalHeader";

export const Body = ({ children, className }) => (
  <div className={cn("body", className)} data-testid="modal__body">
    {children}
  </div>
);

Body.displayName = "ModalBody";

export const Footer = ({ children, className }) => (
  <div className={cn(styles.footer, className)} data-testid="modal__footer">
    {children}
  </div>
);

Footer.displayName = "ModalFooter";

export const Modal = ({ children, className, isOpen: isOpenProp, ...rest }) => {
  useChildren(children, {
    allowed: ["ModalHeader", "ModalBody", "ModalFooter"],
  });
  const isOpen = useDebounce(isOpenProp, isOpenProp ? 0 : 200);

  return (
    <ModalBase
      isOpen={isOpen}
      className={cn("default-modal", className)}
      {...rest}
    >
      {children}
    </ModalBase>
  );
};

Modal.displayName = "Modal";
Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;
