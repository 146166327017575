import Profile from "components/pages/profile";
import GroupOverview from "../components/pages/group-overview";
import MarketData from "../components/pages/market-data";
import Traders from "../components/pages/traders";
import { constants } from "../utilities/constants";
import Onboarding from "components/pages/onboarding";
import MonthlyStatements from "components/pages/monthly-statements";
import Resources from "components/pages/resources";
import Support from "components/pages/support";
import FAQ from "components/pages/faq";

export const routesList = [
    {
        path: constants.paths.TO_GROUP_OVERVIEW,
        component: GroupOverview,
    },
    {
        path: constants.paths.TO_TRADERS,
        component: Traders,
    },
    {
        path: constants.paths.TO_MARKET_DATA,
        component: MarketData,
    },
    {
        path: constants.paths.TO_PERSONAL_INFO,
        component: Profile,
    },
    {
        path: constants.paths.TO_ONBOARDING,
        component: Onboarding,
    },
    {
        path: constants.paths.TO_MONTHLY_STATEMENTS,
        component: MonthlyStatements,
    },
    {
        path: constants.paths.TO_RESOURCES,
        component: Resources,
    },
    // {
    //     path: constants.paths.TO_SUPPORT,
    //     component: Support,
    // },
    {
        path: constants.paths.TO_FAQ,
        component: FAQ,
    },
    {
        path: `${constants.paths.TO_FAQ}/:documentId`,
        component: FAQ,
    },
];