import React from "react";
import Form from "react-bootstrap/Form";
import cn from "classnames";

import "./DateInput.scss";

const DateInput = (props) => {
  const mandatory = typeof props.required !== "undefined" && !props.value;

  const strToBoolean = (s) => {
    s = String(s).toLowerCase();
    return s === "true" || s === "yes" || s === "1";
  };

  return (
    <div className="date-input-wrapper onb-date">
      <div className="date-label">{props.label}</div>
      <Form.Control
        className={cn({
          "not-valid-form": props.formnotvalid,
          "greyed-field": strToBoolean(props.isgreyed),
        })}
        data-date-format="YYYY MM DD"
        type="date"
        {...props}
        mandatory={mandatory.toString()}
      />
      {props.formnotvalid && (
        <div className={"not-valid-form-message"}>
          {props.messagefornotvalid}
        </div>
      )}
    </div>
  );
};

export default DateInput;
