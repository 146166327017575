import { constants } from "utilities/constants";
import { number, addMethod } from "yup";

const { categoryDataType } = constants;

export const isRegularCategory = (category) => {
  return category.name !== "max_loss" && category.name !== "re_enable";
};

export const createFormFieldValidation = (name) => {
  addMethod(number, name, function (activeRiskSetting) {
    return this.test(`isValid${name}`, function (value) {
      const { path, createError } = this;
      if (value === activeRiskSetting.settings?.default) {
        return createError({
          path,
          message: "New value should differ from the current value",
        });
      }

      if (isRegularCategory(activeRiskSetting) && value > 100000000) {
        return createError({
          path,
          message: "Maximum allowed value: 100,000,000",
        });
      }

      if (!isRegularCategory(activeRiskSetting) && value > 10000000) {
        return createError({
          path,
          message: "Maximum allowed value: 10,000,000",
        });
      }

      return true;
    });
  });
};

export const getTradingPlatformName = (traderID, accountsList) => {
  const found = accountsList?.find((t) => t.reference?.client === traderID[0]);
  if (found) {
    return `${found.software} ${found.softwareType}`;
  }
  return null;
};

export const defaultCategories = () => {
  return [
    {
      value: categoryDataType.RISKSETTING,
      label: categoryDataType.RISKSETTING,
    },
    {
      value: "disableAccount",
      label: "Disable Account",
    },
    {
      value: "enableAccount",
      label: "Enable Account",
    },
    // { value: categoryDataType.CUSTOM, label: categoryDataType.CUSTOM },
    { value: categoryDataType.MARKETDATA, label: categoryDataType.MARKETDATA },
  ];
};
