import { useEffect, useState } from "react";
import moment from "moment-timezone";
import cn from "classnames";

import "./styles.scss";
import { Modal } from "components/ui-kit/Modal/Modal";
import { Dropdown } from "components/ui-kit/Dropdown";
import { RadioGroup } from "components/ui-kit/Radio";
import { constants } from "utilities/constants";
import { helperFunctions } from "utilities/helper-functions";
import DateInput from "components/ui-kit/date-input/DateInput";

const tomorrow = moment.utc().add(1, "days").startOf("day");
const nextYear = moment.utc().add(1, "years").startOf("day");

// Set min and max using the moment dates
const min = tomorrow.format("YYYY-MM-DD");
const max = nextYear.format("YYYY-MM-DD");

const { RequestTypes } = constants;

const checkAccountRequestType = (accountRequestType) => {
  const defaultDays = [
    { label: "Immediately", checked: true, value: "100" },
    { label: "Select Date", checked: false, value: "custom" },
  ];

  if (accountRequestType === "disable") {
    defaultDays.splice(1, 0, {
      label: "Today EOD",
      checked: false,
      value: "today",
    });
  }

  return defaultDays;
};

const CreateToggleAccountModal = ({
  accountRequestType = "enable",
  closeModal,
  updateCategory,
  traderIdsList,
  updateTraderIds,
  categories,
  activeTraderIds,
  activeCategory,
  submitModal,
}) => {
  const defaultCategory = categories.find(
    (category) => category.value === activeCategory.value
  );

  const [requestDateOptions, setRequestDateOptions] = useState(() =>
    checkAccountRequestType(accountRequestType)
  );
  const [selectedRequestDate, setSelectedRequestDate] = useState(
    requestDateOptions[0]
  );
  const [activeCalendarDate, setActiveCalendarDate] = useState(null);
  const [isVisibleCalendar, setIsVisibleCalendar] = useState(false);

  const [localActiveTraderIds, setLocalActiveTraderIds] =
    useState(activeTraderIds);

  const defaultTraderId =
    localActiveTraderIds?.length === 1 ? localActiveTraderIds[0] : "";

  // Update local state when activeTraderIds prop changes
  useEffect(() => {
    setLocalActiveTraderIds(activeTraderIds);
  }, [activeTraderIds]);

  const handleFormSubmit = () => {
    localActiveTraderIds.forEach((AT) => {
      const body = {
        type: RequestTypes.TRADING_ACCESS,
        ext: {
          action: accountRequestType === "enable" ? "ENABLE" : "DISABLE",
        },
        ...(selectedRequestDate &&
          selectedRequestDate.value === "100" && { priority: 100 }),
        ...(selectedRequestDate &&
          selectedRequestDate.value === "today" && { eod: true }),
        ...(selectedRequestDate &&
          selectedRequestDate.value === "custom" && {
            duedate: activeCalendarDate,
          }),
      };

      submitModal({
        body,
        type: RequestTypes.TRADING_ACCESS,
        traderRef: AT,
      });
    });
  };

  useEffect(() => {
    setRequestDateOptions(() => checkAccountRequestType(accountRequestType));
  }, [accountRequestType]);

  useEffect(() => {
    if (selectedRequestDate && selectedRequestDate.value === "custom") {
      setIsVisibleCalendar(true);
      setActiveCalendarDate(helperFunctions.userTime(min, "YYYY-MM-DD"));
    } else {
      setIsVisibleCalendar(false);
    }
  }, [selectedRequestDate]);

  const onTraderIdChange = (selectedTraderId) => {
    setLocalActiveTraderIds([selectedTraderId]);
    updateTraderIds(localActiveTraderIds, true);
  };

  const handleDateChange = (e) => {
    const selectedDate = moment.utc(e.target.value).startOf("day").toDate();
    setActiveCalendarDate(selectedDate);
  };

  return (
    <div className="modal-window">
      <Modal
        className="toggle-account-modal cabinet-modals"
        overlayClassName="request-overlay"
        styleType="zimtra-primary"
        isOpen={true}
        hideBlur
        hasCloseIcon={false}
        onClickOutside={closeModal}
      >
        <Modal.Header
          className="modal-inner-header"
          onClose={closeModal}
          title={<h2 className="zimtra-h2">New Request</h2>}
        />
        <Modal.Body className="modal-inner-body">
          <div className="d-flex align-items-top">
            <div className="left-side">Category:</div>
            <div>
              <Dropdown
                options={categories}
                defaultValue={defaultCategory.value}
                className="request-dropdown"
                placeholder="Select category"
                onChange={updateCategory}
              />
            </div>
          </div>
          <div className="d-flex align-items-top">
            <div className="left-side">TraderID:</div>
            {localActiveTraderIds?.length > 1 ? (
              <div className="left-side">{localActiveTraderIds.join(", ")}</div>
            ) : (
              <div>
                <Dropdown
                  options={traderIdsList}
                  defaultValue={defaultTraderId}
                  className="request-dropdown select-category-dropdown"
                  placeholder="Enter TraderID"
                  onChange={(value) => onTraderIdChange(value)}
                />
              </div>
            )}
          </div>
          <hr />
          <div className="d-flex align-items-center radio-group-container">
            <RadioGroup
              itemDirection="column"
              data={requestDateOptions}
              onChange={(item) => {
                setSelectedRequestDate(item);
              }}
            />
          </div>
          <div
            className={cn("d-flex align-items-center calendar-container", {
              disabled: isVisibleCalendar !== true,
            })}
          >
            <DateInput
              isgreyed={isVisibleCalendar ? null : "true"}
              name="start"
              type="date"
              min={min}
              max={max}
              value={moment.utc(activeCalendarDate).format("YYYY-MM-DD")}
              onChange={handleDateChange}
            />
          </div>
          <hr />
        </Modal.Body>
        <Modal.Footer className="modal-inner-footer d-flex justify-content-between">
          <button
            className="main-button"
            disabled={!selectedRequestDate}
            onClick={handleFormSubmit}
          >
            Create
          </button>
          <button
            className="main-button cancel-button"
            onClick={closeModal}
            label=""
          >
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreateToggleAccountModal;
