import { instance, setHeader } from "./auth-api";

const accounturl = "/account";

export const marketdataAPI = {
  getMarketdataProviders: async () => {
    const response = await instance.get(
      "/static/marketdataproviders",
      { headers: setHeader() }
    );
    return response;
  },
  getTradingPlatforms: async () => {
    const response = await instance.get(
      "/static/tradingplatforms",
      { headers: setHeader() }
    );
    return response;
  },
};
