import { instance, setHeader } from "./auth-api";

const docurl = "/document";

export const documentAPI = {
  fetchDocumentListFromS3: async () => {
    const response = await fetch(
      "https://zimtra-publica-data.s3.us-east-2.amazonaws.com/faq-markdown-data/documents-list.json"
    );
    return response;
  },
  fetchDocumentFromS3: async (id) => {
    const response = await fetch(
      `https://zimtra-publica-data.s3.us-east-2.amazonaws.com/faq-markdown-data/markdowns/${id}.md`
    );
    return response.text();
  },
  deleteDocument: async (id) => {
    const response = await instance.delete(`${docurl}/${id}`, {
      headers: setHeader(),
    });
    return response;
  },
  getImage: async (src) => {
    // const response = await instance.get(`${docurl}/${src}`, {
    //   headers: setHeader(),
    //   responseType: "blob", // important
    // });
    // return response;
  },
  getFile: async (id) => {
    // const response = await instance.get(`/fs/file/${id}`, {
    //   headers: setHeader(),
    //   responseType: "blob", // important
    // });
    // return response;
  },

  // ********** attachments ********
  getAttachment: async (currentDocument, name) => {
    // const response = await instance.get(
    //   `${docurl}/attachment/${currentDocument}/${name}`,
    //   {
    //     headers: setHeader(),
    //     responseType: "blob", // important
    //   }
    // );
    // return response;
  },
};
