import { separateConstants } from "utilities/constants";

const tableFormattedDataForEachElement = (item, metadata) => {
  const { status, personal, riskSettings, reference, software } = item;
  let formattedStatus = status;
  let formattedFullName = "N/A";

  // Format FullName
  if (personal?.firstName || personal?.lastName) {
    formattedFullName = `${personal?.firstName ?? ""} ${personal?.lastName ?? ""}`.trim();
  }

  // Format Account state
  switch (formattedStatus) {
    case "AccountEnabled":
      formattedStatus = "Enabled";
      break;
    case "AccountDisabled":
      formattedStatus = "Disabled";
      break;
    case "AccountCreated":
      formattedStatus = "Created";
      break;
    default:
      formattedStatus = "Suspended";
      break;
  }

  const platform = software;
  const formattedRiskSettings = {};

  metadata?.forEach((riskSetting) => {
    const { name, label, settings } = riskSetting;
    let include = true;

    // Check if the risk setting is applicable for the current platform
    if (settings?.availablePlatforms) {
      include = settings.availablePlatforms.includes(platform);
    }

    if (include) {
      // Determine the label to use
      let labelToUse = label;
      if (settings?.platformToLabel && settings.platformToLabel[platform]) {
        labelToUse = settings.platformToLabel[platform];
      }

      // Get the value from riskSettings
      const value =
        riskSettings?.[name] !== null && riskSettings?.[name] !== undefined
          ? riskSettings[name].toString()
          : "N/A";

      // Assign to formattedRiskSettings
      formattedRiskSettings[labelToUse] = value;
    }
  });

  return {
    checked: false,
    starred: false,
    traderId: reference?.client || "N/A",
    "Account State": formattedStatus || "N/A",
    platform: platform || "N/A",
    "Full Name": formattedFullName,
    ...formattedRiskSettings,
  };
};

const tableFormattedData = (onlyActive, requestsItemsDataALL, metadata) => {
  let RI = [...requestsItemsDataALL];

  if (onlyActive) {
    RI = RI.filter(
      (r) => !separateConstants.notActiveRequestsStates.includes(r.status)
    );
  }

  if (RI.length) {
    return RI.map((item) => tableFormattedDataForEachElement(item, metadata));
  }

  // Generate default risk settings based on metadata
  const defaultRiskSettings = {};
  metadata?.forEach((riskSetting) => {
    const { label, settings } = riskSetting;
    let labelToUse = label;

    // Determine the label to use based on platformToLabel (assuming default platform)
    if (settings?.platformToLabel) {
      const platforms = Object.keys(settings.platformToLabel);
      if (platforms.length > 0) {
        labelToUse = settings.platformToLabel[platforms[0]] || label;
      }
    }

    defaultRiskSettings[labelToUse] = "N/A";
  });

  return [
    {
      traderId: "N/A",
      "Account State": "N/A",
      platform: "N/A",
      "Full Name": "N/A",
      ...defaultRiskSettings,
    },
    "no-data",
  ];
};


const findActiveTP = (allTPs, tpToFindByName) => {
  if (allTPs && tpToFindByName) {
    const firstChar = tpToFindByName.split(" ")[0];
    const secondChar = tpToFindByName.split(" ")[1];
    const foundTp = allTPs.find(
      (tp) => tp.software === firstChar && tp.softwareType === secondChar
    );
    if (foundTp) {
      return foundTp;
    } else return null;
  }
  return null;
};

const TradersHelpers = {
  tableFormattedData,
  tableFormattedDataForEachElement,
  findActiveTP,
};

export default TradersHelpers;
