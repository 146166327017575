import { useSelector } from "react-redux";

import { Modal } from "components/ui-kit/Modal/Modal";
import { getTradingPlatformName } from "./helpers";
import MarketDataPageModal from "./marketdata-modal/MarketDataPageModal";

import "./styles.scss";
import { getAccountStatusText } from "./marketdata-modal/helpers";

const CreateNewMarketDataRequest = ({
  closeModal,
  activeTraderIds,
}) => {
  const { TGAccountsList } = useSelector((state) => state.tradeGroup);

  const defaultTraderIds =
    activeTraderIds?.length === 1 ? activeTraderIds[0] : "";

    return (
    <div className="modal-window">
      <Modal
        className="marketdata-request-modal cabinet-modals"
        overlayClassName="request-overlay"
        styleType="zimtra-primary"
        isOpen={true}
        hideBlur
        hasCloseIcon={false}
        onClickOutside={closeModal}
      >
        <Modal.Header
          className="modal-inner-header"
          onClose={closeModal}
          hasCloseIcon
          title={<h2 className="zimtra-h2">New Request</h2>}
        />
        <Modal.Body className="modal-inner-body">
          <div className="d-flex align-items-top modal-row">
            <div className="left-side">TraderID:</div>
            {activeTraderIds?.length > 1 ? (
              <div className="left-side">{activeTraderIds.join(", ")}</div>
            ) : (
              <>
                <div>{defaultTraderIds}</div>
                <div className="acc-status-text">
                  {getAccountStatusText(defaultTraderIds, TGAccountsList)}
                </div>
              </>
            )}
          </div>
          <div className="d-flex align-items-top modal-row first">
            <div className="left-side">Platform:</div>
            <div>{getTradingPlatformName(activeTraderIds, TGAccountsList)}</div>
          </div>
          <MarketDataPageModal
            defaultTraderIds={activeTraderIds}
            TGAccountsList={TGAccountsList}
            closeModal={closeModal}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreateNewMarketDataRequest;
