import { types } from "./types";

const {
  tg: {
    SET_TG_ACCOUNTS_LIST,
    SET_TG_BALANCE,
    SET_TG_REQUESTS_LIST,
    SET_RS_METADATA,
    UPDATE_REQUEST_LIST,
    ADD_TG_REQUEST,
    SET_TGs_LIST,
    SET_AGENCY_LIST
  },
} = types;

const initialState = {
  TGsList: null,
  TGAccountsList: null,
  TGBalance: null,
  TGRequestsList: null,
  riskSettingMetadata: null,
  agencyList: null,
};

const tradeGroupReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TGs_LIST:
      return { ...state, TGsList: payload };
    case SET_AGENCY_LIST:
      return { ...state, agencyList: payload };
    case SET_TG_ACCOUNTS_LIST:
      return { ...state, TGAccountsList: payload };
    case SET_TG_BALANCE:
      return { ...state, TGBalance: payload };
    case SET_TG_REQUESTS_LIST:
      return { ...state, TGRequestsList: payload };
    case SET_RS_METADATA:
      return {
        ...state,
        riskSettingMetadata: payload,
      };
    case ADD_TG_REQUEST:
      return {
        ...state,
        TGRequestsList: state.TGRequestsList
          ? [...state.TGRequestsList, payload]
          : [payload],
      };
    case UPDATE_REQUEST_LIST:
      return {
        ...state,
        TGRequestsList: state.TGRequestsList
          ? state.TGRequestsList.map((request) =>
              request._id === payload._id ? payload : request
            )
          : [payload],
      };
    default:
      return state;
  }
};

export default tradeGroupReducer;
