import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";

import TableComponent from "components/ui-kit/react-table/TableComponent";
import { supportTableUtils } from "components/ui-kit/react-table/supportTableUtils";
import SupportStateController from "components/ui-kit/state-controller/SupportStateController";
import TradersHelpers from "./helpers";
import { NewRequest } from "components/requests/NewRequest";
import { constants } from "utilities/constants";
import { tradeGroupThunks } from "action-thunks/tradeGroup-thunks";

import styles from "./styles.module.scss";

const { tableFormattedData, findActiveTP } = TradersHelpers;
const { categoryDataType } = constants;

const Traders = ({ adminGroupData }) => {
  const { TGAccountsList, riskSettingMetadata } = useSelector(
    (state) => state.tradeGroup
  );
  const { tradingPlatforms } = useSelector((state) => state.marketdata);

  const [checkedItems, setCheckedItems] = useState([]);
  const [visibleItems, setVisibleItems] = useState(0);
  const [sortedTraders, setSortedTraders] = useState(null);
  const [activeTP, setActiveTP] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoadingList, setIsLoadingisLoadingList] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const activeList = useMemo(() => {
    if (sortedTraders && activeTP) {
      const joinedActiveTPName = `${activeTP?.software} ${activeTP?.softwareType}`;
      return sortedTraders[joinedActiveTPName];
    } else {
      return [];
    }
  }, [sortedTraders, activeTP]);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchRequestsPeriodically = () => {
      if (!isModalOpen) {
        if (isInitialLoad) setIsLoadingisLoadingList(true);
        dispatch(
          tradeGroupThunks.getTGAccountsListTC([
            "openMDrequest",
            "marketData",
            "accountsOnly",
            "firstEnableRequest",
            "riskSettings",
          ])
        ).finally(() => {
          setIsInitialLoad(false);
          setIsLoadingisLoadingList(false);
        });
      }
    };

    fetchRequestsPeriodically();

    const intervalId = setInterval(fetchRequestsPeriodically, 3000);

    return () => clearInterval(intervalId);
  }, [isModalOpen, dispatch, isInitialLoad]);

  useEffect(() => {
    if (TGAccountsList !== null) {
      let sortedAccounts = null;
      if (TGAccountsList.length) {
        sortedAccounts = {};
        TGAccountsList.forEach((item) => {
          const { software, softwareType } = item;
          if (software) {
            const combinedValue = `${software} ${softwareType}`;

            if (!sortedAccounts[combinedValue]) {
              sortedAccounts[combinedValue] = [];
            }

            sortedAccounts[combinedValue].push(item);
          }
        });
      }

      setSortedTraders(sortedAccounts);
    }
  }, [TGAccountsList]);

  useEffect(() => {
    if (
      sortedTraders &&
      Object.keys(sortedTraders).length > 0 &&
      tradingPlatforms &&
      !activeTP
    ) {
      const newActiveTP = findActiveTP(
        tradingPlatforms,
        Object.keys(sortedTraders)[0]
      );
      setActiveTP(newActiveTP);
    }
  }, [sortedTraders, tradingPlatforms, activeTP]);

  useEffect(() => {
    if (activeTP && sortedTraders) {
      setVisibleItems((prevVisibleItems) => {
        // Only set if previous is zero (initial load) or if activeList has fewer items
        const defaultVisibleItems =
          activeList.length > 10 ? 10 : activeList.length;
        if (prevVisibleItems === 0 || prevVisibleItems > defaultVisibleItems) {
          return defaultVisibleItems;
        }
        return prevVisibleItems;
      });
    }
  }, [activeTP]);

  const checkboxColumnsHandler = (row) => {
    setCheckedItems((prev) => {
      const currentIndex = prev.find((p) => p.id === row.id);
      if (currentIndex === undefined) {
        // Not currently checked, add it
        return [...prev, row];
      } else {
        // Currently checked, remove it
        return prev.filter((r) => r.id !== row.id);
      }
    });
  };
  const starColumsHandler = (row, status) => {};

  const onTPButtonClick = (tpName) => {
    const fountTP = findActiveTP(tradingPlatforms, tpName);
    if (fountTP) {
      setActiveTP(fountTP);
      setCheckedItems([]);
    }
  };

  console.log(checkedItems);

  return (
    <div className={cn("page-wrapper", styles.traders)}>
      <div className={cn(styles.header, "d-flex f-gap-20 align-items-center")}>
        <h3>Traders</h3>
        {!isLoadingList && (
          <div className={styles.tpBlock}>
            {sortedTraders &&
              Object.keys(sortedTraders)?.map((i) => (
                <span
                  onClick={() => onTPButtonClick(i)}
                  className={cn(styles.tpItem, {
                    [styles["active-trading-platform"]]:
                      i === `${activeTP?.software} ${activeTP?.softwareType}`,
                  })}
                  key={crypto.randomUUID()}
                >
                  {i}
                </span>
              ))}
          </div>
        )}
      </div>
      {!isLoadingList && activeList && (
        <div className={styles.body}>
          <NewRequest
            setIsModalOpen={setIsModalOpen}
            adminGroupData={adminGroupData}
            renderFullListAfterRequest={false}
            multipleTraders={checkedItems}
            setCheckedItems={setCheckedItems}
            filteredCategoriesArray={[categoryDataType.MARKETDATA]}
          />
          <div className={styles["table-body"]}>
            <TableComponent
              data={tableFormattedData(
                true,
                activeList.slice(-visibleItems),
                riskSettingMetadata
              )}
              dateColumns={["created", "updated"]}
              checkedItems={checkedItems}
              checkboxColumsHandler={checkboxColumnsHandler}
              starColumsHandler={starColumsHandler}
              formattingForDateColumns={supportTableUtils.tableTimeFormat}
              showSearch={false}
              // isLoading={isLoading}
              //   setCellClassName={setCellClassName}
              itemsToUseSorting={[
                "subgroup",
                "traderid",
                "accountstate",
                "platform",
                "fullname",
              ]}
            />
          </div>
          <SupportStateController
            setVisibleItems={setVisibleItems}
            visibleItems={visibleItems}
            items={activeList}
          />
        </div>
      )}
    </div>
  );
};

export default Traders;
