import { setNotificationChildrenAC } from "action-thunks/actions";
import CopyToClipboard from "components/ui-kit/copy-to-clipboard/CopyToClipboard";

export const notificationHelpers = {
  errorRequest: (text, dispatch) => {
    const id = crypto.randomUUID();
    dispatch(
      setNotificationChildrenAC({id, element: 
        <div className="notification">
          <p>For Trader ID:</p>
          <div className="notification-list">
            <CopyToClipboard text={text} />
          </div>
        </div>
      })
    );
  },
};
