import moment from "moment/moment";

import { supportTableUtils } from "components/ui-kit/react-table/supportTableUtils";
import { separateConstants } from "utilities/constants";

import styles from "./styles.module.scss";
import { getBundleName } from "components/requests/modals/marketdata-modal/helpers";

const tableFormattedDataForEachElement = (item, marketBundles, marketdataProviders, tradingPlatformRef) => {
  const { status, personal, marketData, reference, software } = item;
  let formattedStatus = status;
  let formattedFullName = "N/A";
  let formatRequestedMD = "N/A";
  let formatEffectiveDate = "N/A";
  let formatCurrentBundleName = "N/A";

  // additional format for => FullName column
  if (personal?.firstName || personal?.lastName) {
    formattedFullName = `${personal?.firstName} ${personal?.lastName}`;
  }
  // additional format for => Account state column
  if (formattedStatus === "AccountEnabled") {
    formattedStatus = "Enabled";
  } else if (formattedStatus === "AccountDisabled") {
    formattedStatus = "Disabled";
  } else if (formattedStatus === "AccountCreated") {
    formattedStatus = "Created";
  } else {
    formattedStatus = "Suspended";
  }

  if (marketData) {
    formatCurrentBundleName = getBundleName({
      markets: marketData.ext.markets?.map(m => m.market) || [],
      marketdataProviders,
      marketBundles,
      tradingPlatformRef,
    });
  }

  if (item.marketDataRequest) {
    formatRequestedMD = (
      <div>
        <div className={styles.addMarketsBlock}>
          {item.marketDataRequest.ext.addMarkets?.map((m, i) => (
            <span key={crypto.randomUUID()}>{i === 0 && "+"} {m.market}</span>
          ))}
        </div>
        <div className={styles.removeMarketsBlock}>
          {item.marketDataRequest.ext.removeMarkets?.map((m, i) => (
            <span key={crypto.randomUUID()}>{i === 0 && "-"} {m.market}</span>
          ))}
        </div>
      </div>
    );

    formatEffectiveDate = moment(
      item.marketDataRequest.schedule.duedate
    ).format("YYYY-MM-DD");
  }

  return {
    checked: false,
    starred: false,
    "Full Name": formattedFullName,
    traderId: reference?.client,
    // subgroup: "N/A",
    paltform: software,
    "Account State": formattedStatus || "N/A",
    "Date Applied":
      supportTableUtils.tableTimeFormat(marketData?.date, true) || "N/A",
    "Current Market Data":
      marketData?.ext?.markets?.map((m) => m.market).join(", ") || "N/A",
    "Current Bundle": formatCurrentBundleName,
    "Requested Market Data": formatRequestedMD,
    // RequestedBundle: "N/A",
    "Effective Date": formatEffectiveDate,
  };
};

const tableFormattedData = (onlyActive, marketDataAll, marketBundles, marketdataProviders, tradingPlatformRef) => {
  let MD = marketDataAll.map((i) => i);

  if (onlyActive) {
    MD = marketDataAll?.filter(
      (r) => !separateConstants.notActiveRequestsStates.includes(r.status)
    );
  }

  if (MD?.length)
    return MD?.map((item) => tableFormattedDataForEachElement(item, marketBundles, marketdataProviders, tradingPlatformRef));

  return [
    {
      checked: false,
      starred: false,
      subgroup: "N/A",
      traderId: "N/A",
      "Account state": "N/A",
      paltform: "N/A",
      fullName: "N/A",
      "Max Loss": "N/A",
      "Day BP": "N/A",
      "Pre & Post Market BP": "N/A",
      "Order Day BP": "N/A",
      "Order Pre & Post Market BP": "N/A",
      "Max Exposure": "N/A",
      "Allowed Overnight Exposure": "N/A",
    },
    "no-data",
  ];
};

const setCellClassName = (cell) => {
  if (
    cell &&
    (cell.column?.Header === "CurrentMarketData" ||
      cell.column?.Header === "RequestedMarketData")
  ) {
    return [styles["CurrentMarketData"]];
  }
};

const findActiveTP = (allTPs, tpToFindByName) => {
  if (allTPs && tpToFindByName) {
    const firstChar = tpToFindByName.split(" ")[0];
    const secondChar = tpToFindByName.split(" ")[1];
    const foundTp = allTPs.find(
      (tp) => tp.software === firstChar && tp.softwareType === secondChar
    );
    if (foundTp) {
      return foundTp;
    } else return null;
  }
  return null;
};

const TradersHelpers = {
  tableFormattedData,
  setCellClassName,
  findActiveTP,
};

export default TradersHelpers;
