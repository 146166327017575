import { types } from "./types.js";

const { utils } = types;

const initialState = {
  isLoading: false,
  notificationChildren: null,
  error: null,
};

const utilsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case utils.SET_IS_LOADING:
      return { ...state, isLoading: payload };
    case utils.SET_NOTIFICATION_CHILDREN:
      return {
        ...state,
        notificationChildren: [
          ...(state.notificationChildren || []),
          { id: payload.id, element: payload.element },
        ],
      };
    case utils.REMOVE_NOTIFICATION:
      return {
        ...state,
        notificationChildren: state.notificationChildren.filter(
          (notification) => notification.id !== payload
        ),
      };
    case utils.GET_ERRORS:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
};

export default utilsReducer;
