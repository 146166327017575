import axios from "axios";

import { constants } from "../utilities/constants";

const {
  tokens: { LOG_IN_TOKEN },
  paths: { TO_LOGIN },
} = constants;

export const AuthAPI = {
  login: async (userData) => {
    const response = await instance.post("/users/login", userData, {
      headers: setHeader(),
    });
    return response;
  },
  logout: async () => {
    const response = await instance.get("/logout", { headers: setHeader() });
    return response;
  },
  getToken: () => {
    if (!!localStorage.getItem(LOG_IN_TOKEN)) {
      return localStorage.getItem(LOG_IN_TOKEN);
    }
    if (!!sessionStorage.getItem(LOG_IN_TOKEN)) {
      return sessionStorage.getItem(LOG_IN_TOKEN);
    }
    return null;
  },
  getSessionToken: () => sessionStorage.getItem("token"),
};

export const setHeader = () => {
  if (AuthAPI.getToken()?.length > 1) {
    return { Authorization: `Bearer ${AuthAPI.getToken()}` };
  }
  if (AuthAPI.getSessionToken()?.length > 1) {
    return { Authorization: `Bearer ${AuthAPI.getSessionToken()}` };
  } else return null;
};

const baseURL = process.env.REACT_APP_API_URL;
export const instance = axios.create({
  baseURL,
});

instance.interceptors.response.use(null, (error) => {
  if (
    !axios.isCancel(error) &&
    error.response &&
    error.response.status === 401
  ) {
    if (window.location.hostname === "localhost") {
      window.location.replace(TO_LOGIN);
    }
  }
  return Promise.reject(error);
});
