import { useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./Dropdown.module.scss";

export const Dropdown = ({
  label,
  disabled,
  openMenu,
  alertMessage,
  placeholder,
  defaultValue,
  options,
  size,
  formatOptionLabel,
  onChange,
  styleType = "primary",
}) => {
  const [localValue, setLocalValue] = useState(defaultValue || "");
  const [menuIsOpened, setMenuIsOpened] = useState(openMenu || false);

  const menuRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (menuIsOpened && menuRef.current && dropdownRef.current) {
      const modal = document.querySelector(".cabinet-modals");  // Change to your modal selector
      if (modal) {
        const modalRect = modal.getBoundingClientRect();
        const dropdownRect = dropdownRef.current.getBoundingClientRect();

        let availableHeight = modalRect.bottom - dropdownRect.top - 10;
        dropdownRef.current.style.maxHeight = `${availableHeight}px`;
      }
    }
  }, [menuIsOpened]);

  useEffect(() => {
    const listener = ({ target }) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!menuRef.current || menuRef.current.contains(target)) {
        return;
      }
      setMenuIsOpened(false);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [menuRef, menuIsOpened]);

  useEffect(() => {
    const foundOption = options?.find((o) => o.value === localValue);
    if (localValue && !foundOption) {
      onChange(null);
      setLocalValue("")
    }
  }, [options, localValue, onChange])

  const onChangeHandler = (value) => {
    onChange?.(value);
    setLocalValue(value);
  };

  const renderOptionsBlock = (isGrouped) => {
    const chosenItem = (value) =>
      value === localValue ? <div className={styles.chosen}></div> : null;

    const mappedItems = (item, index, itemsToMap) => {
      if (formatOptionLabel) {
        return formatOptionLabel(
          item.value,
          item.label,
          isGrouped ? item.group : undefined
        );
      }
      const showHR =
        isGrouped &&
        !!itemsToMap[index - 1] &&
        itemsToMap[index - 1].group !== itemsToMap[index].group;
      return (
        <>
          {showHR ? <hr /> : null}
          <div
            key={`${item.label}_${item.value}`}
            className={cn({ [styles.disabled]: item.disabled })}
            {...(!item.disabled && {
              onClick: () => onChangeHandler(item.value),
            })}
          >
            <span>{item.label}</span>
            {chosenItem(item.value)}
          </div>
        </>
      );
    };

    const itemsToMap = isGrouped
      ? options.sort((a, b) => {
          if (a.group && b.group) return a.group.localeCompare(b.group);
          return 1;
        })
      : options;

    return (
      <div className={styles.optionsBlock} ref={dropdownRef} key="options-block">
        {itemsToMap?.map((item, index) => mappedItems(item, index, itemsToMap))}
      </div>
    );
  };

  const onMenuClick = () => {
    if (!disabled) setMenuIsOpened(!menuIsOpened);
  };

  const setTypographyLabel = () => {
    const foundOption = options?.find((o) => o.value === localValue);
    return localValue && foundOption ? foundOption.label : "" ||  placeholder || "";
  };
  return (
    <div
      className={cn([styles[styleType]], {
        [styles.disabled]: disabled,
        [styles.shortWidth]: size === "short",
      })}
    >
      {label && <span>{label}</span>}
      <div
        role="menuitem"
        tabIndex={0}
        onClick={onMenuClick}
        className={styles.dropdownMenu}
        ref={menuRef}
      >
        <span
          key="dropdown-field"
          className={cn(styles.dropdownField, {
            [styles.placeholder]: !localValue,
            [styles.error]: alertMessage,
            [styles.disabled]: disabled,
            [styles.opened]: menuIsOpened,
          })}
        >
          <span>{setTypographyLabel()}</span>
        </span>
        <div
          key="dropdown-menu-icon"
          className={cn(styles.dropdownMenuIcon, {
            [styles.disabled]: disabled,
            [styles.withAlert]: alertMessage,
          })}
        >
          <img
            src={`${process.env.PUBLIC_URL}/icons/${
              menuIsOpened ? "chevron-down" : "chevron-down"
            }.svg`}
            className={styles.arrowIcon}
            alt="shevron"
          />
        </div>
        {menuIsOpened && renderOptionsBlock(options?.some((i) => !!i.group))}
        {alertMessage && !menuIsOpened && (
          <div className={styles.errorStyle}>
            <span>{alertMessage}</span>
          </div>
        )}
      </div>
    </div>
  );
};
